//material ui hooks/components
import { createMuiTheme } from '@material-ui/core/styles'
import createBreakpoints from '@material-ui/core/styles/createBreakpoints'

const arcBlue = "#1a73e8"
const arcOrange = "#ffa500"

const breakpoints = {
    xs: 0,
    ss: 475,
    sm: 600,
    mm: 700,
    md: 960,
    lg: 1280,
    xl: 1920
}

const breakpointsFull = createBreakpoints({
    values: { ...breakpoints },
    keys: Object.keys(breakpoints),
})

export default createMuiTheme({
    props: {
        MuiCard: {
            elevation: 0,
        }
    },
    overrides: {
        MuiOutlinedInput: {
            root: {
                '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
                    borderColor: 'rgba(0,0,0,0.4)',
                }
            }
        },
        MuiCssBaseline: {
            '@global': {
                body: {
                    margin: 0,
                    overflowX: 'hidden'
                },
                '*::-webkit-scrollbar': {
                    display: "none"
                },
                '*-ms-overflow-style': 'none'
            }
        },
        MuiFab: {
            root: {
                position: 'fixed',
                bottom: '1rem',
                right: '1rem'
            }
        },
        MuiPopover: {
            root: {
                zIndex: '1301 !important',
            }
        },
        MuiIconButton: {
            root: {
                marginBottom: 'auto',
                marginTop: 'auto',
                '&:hover': {
                    backgroundColor: '#ffffff33'
                }
            }
        },
        MuiFormControlLabel: {
            root: {
                marginBottom: '-0.4rem'
            }
        }
    },
    palette: {
        common: {
            blue: `${arcBlue}`,
            orange: `${arcOrange}`
        },
        primary: {
            main: `${arcBlue}`
        },
        secondary: {
            main: `${arcBlue}`
        }
    },
    typography: {
        title: {
            textTransform: 'none',
            fontWeight: '400',
            fontSize: '1.2rem',
        }
    },
    breakpoints: breakpointsFull
})