//react
import React from 'react'

//redux
import { useDispatch, useSelector } from "react-redux"

//material ui hooks/components
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import Tooltip from '@material-ui/core/Tooltip'
import TextField from '@material-ui/core/TextField'
import ChipInput from 'material-ui-chip-input'


//style
const useStyles =
    makeStyles(
        (theme) => ({
            root: {
                '& > *': {
                    width: '7.5rem',
                    marginBottom: '2rem',
                    display: 'table-row-group',
                }
            },
            valueBox: {
                display: 'initial!important'
            },
            settingsBox: {
                display: 'inline-grid!important',
                width: '22rem'
            },
            checkBoxRoot: {
                marginRight: '0.3rem!important',
                fontSize: '13px!important',
            },
            removeValueVisible: {
                marginLeft: 'auto',
                display: 'block',
                color: '#dc004e'
            },
            removeValue: {
                display: 'none'
            },
            title: {
                fontSize: 13,
            },
            checkBoxLabel: {
                fontSize: '13px!important'
            },
            concatenationParameter: {
                marginTop: '0.6rem',
                fontSize: 13,
                marginLeft: '2rem'
            },
            helperDiv: {
                marginTop: '0.90rem',
                display: 'flex'
            },
            concatenateDivNotVisble: {
                marginTop: '0.75rem',
                display: 'none'
            },
            multipleOptionsDiv: {
                display: 'inline-flex'

            },
            helpIcon: {
                fontSize: '0.9rem'
            },
            inputRoot: {
                width: '2.6rem!important',
                marginLeft: '1rem',
                fontSize: '13px',
                fontWeight: '500'
            },
            inputLabel: {
                textAlign: 'center!important'
            },
            chipBox: {
                marginTop: '0.6rem',
                marginBottom: '1.6rem',
                paddingTop: '0px'
            },
            body1Text: {
                fontSize: "2"
            },
            chipBoxRemoveButtonVisible: {
                marginTop: '0.6rem',
                marginBottom: '0.6rem',
                paddingTop: '0px'
            },
            chipInputRoot: {
                paddingTop: '8px!important',
                fontSize: '13px',
                minWidth: '16rem'
            }
        }))


//custom component function
const ConfigurationValueList = () => {

    const classes = useStyles()
    const dispatch = useDispatch()

    //redux states
    const optional =
        useSelector(
            state =>
                state
                    .value
                    .menu
                    .create
                    .valueList
                    .stepConfigurationSettings
                    .optional)

    const multiple =
        useSelector(
            state =>
                state
                    .value
                    .menu
                    .create
                    .valueList
                    .stepConfigurationSettings
                    .multiple)

    const concatCharacter =
        useSelector(
            state =>
                state
                    .value
                    .menu
                    .create
                    .valueList
                    .stepConfigurationSettings
                    .concatenatorCharacter)

    const options =
        useSelector(
            state =>
                state
                    .value
                    .menu
                    .create
                    .valueList
                    .stepConfigurationOptions
                    .options)


    //event handlers
    const handleAddOption =
        React.useCallback(
            (option) => {
                dispatch(
                    {
                        type: 'ADD_OPTION_CONFIGURATION_STEP_VALUE_MENU_CREATE',
                        option: option
                    })
            }, [dispatch])

    const handleDeleteOption =
        React.useCallback(
            (option) => {
                dispatch(
                    {
                        type: 'REMOVE_OPTION_CONFIGURATION_STEP_VALUE_MENU_CREATE',
                        option: option
                    })
            }, [dispatch])

    const handleRemoveAllOptions =
        React.useCallback(
            () => {
                dispatch(
                    {
                        type: 'REMOVE_ALL_OPTIONS_CONFIGURATION_STEP_VALUE_MENU_CREATE',
                    })
            }, [dispatch])


    const handleChangeConcatenatorParameter =
        React.useCallback(
            (event) => {
                dispatch(
                    {
                        type: 'CHANGE_CONCATENATOR_CHARACTER_CONFIGURATION_STEP_VALUE_MENU_CREATE',
                        character: event.target.value
                    })
            }, [dispatch])

    const handleCheckboxChange =
        React.useCallback(
            (setting, valueType, block) => {
                dispatch(
                    {
                        type: 'TOGGLE_CHECKBOX_CONFIGURATION_STEP_VALUE_MENU_CREATE',
                        setting: setting,
                        valueType: valueType,
                        block: block
                    })
            }, [dispatch])


    //additional data
    const definitions = {
        optionsTitle: 'Value Options',
        optionsPlaceholder: 'Type here the options for the value...',
        settingsTitle: 'Value Settings',
        settingsOptional: 'Make this value optional',
        settingsMultipleOptions: 'Allow to select multiple options',
        settingsMultipleTooltip: `Allowing multiple options to be selected concatenates the choosen options using a character of your election.
        This is specially useful when, to respresent a value, more than one option is needed. As an example, if there is
        a campaign running in two different countries and if a given value represents 'Country', you may be interested
        on selecting more than one option/country.`,
        settingsConcatParameter: 'Concatenation parameter:'
    }


    //custom component
    return (
        <form
            key="valueList-configuration-step-form"
            className={classes.root}
            noValidate
            autoComplete="off">
            <Typography
                component={'div'}
                variant='subtitle1'
                className={classes.title}
                color="textSecondary"
                gutterBottom>
                {definitions.optionsTitle}
            </Typography>
            <div
                className={classes.valueBox}
                id="chipWrapper">
                <ChipInput
                    id="chipInput"
                    inputRef={input => input && input.focus()}
                    classes={{
                        inputRoot: classes.chipInputRoot
                    }}
                    className={options.length > 10 ? classes.chipBoxRemoveButtonVisible : classes.chipBox}
                    placeholder={definitions.optionsPlaceholder}
                    onPaste={(event) => {
                        event.preventDefault()
                        handleAddOption(event.clipboardData.getData('Text').split('\n'))
                    }}
                    value={options}
                    newChipKeys={["Control"]}
                    variant="outlined"
                    onAdd={(chip) => handleAddOption([chip])}
                    onDelete={(chip) => handleDeleteOption(chip)}
                />
                <Button
                    className={options.length > 10 ? classes.removeValueVisible : classes.removeValue}
                    onClick={handleRemoveAllOptions}>
                    Remove All
             </Button>
            </div>
            <Typography
                component={'div'}
                variant='subtitle1'
                className={classes.title}
                color="textSecondary"
                gutterBottom>
                {definitions.settingsTitle}
            </Typography>
            <div
                className={classes.settingsBox}>
                <FormControlLabel
                    value=""
                    control={
                        <Checkbox
                            checked={optional}
                            onChange={() => handleCheckboxChange('optional', 'valueList', 'stepConfigurationSettings')}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                    }
                    label={definitions.settingsOptional}
                    size="small"
                    classes={{ label: classes.checkBoxLabel }}
                    labelPlacement="end"
                />
                <div
                    className={classes.multipleOptionsDiv}>
                    <FormControlLabel
                        value=""
                        control={
                            <Checkbox
                                checked={multiple}
                                onChange={() => handleCheckboxChange('multiple', 'valueList', 'stepConfigurationSettings')}
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                        }
                        label={definitions.settingsMultipleOptions}
                        classes={{ root: classes.checkBoxRoot, label: classes.checkBoxLabel }}
                        labelPlacement="end">

                    </FormControlLabel>
                    <div className={classes.helperDiv}>
                        <Tooltip
                            title={
                                <React.Fragment>
                                    {definitions.settingsMultipleTooltip}
                                </React.Fragment>
                            }>
                            <HelpOutlineIcon
                                className={classes.helpIcon} />
                        </Tooltip>
                    </div>
                </div>
                <div
                    className={multiple ? classes.helperDiv : classes.concatenateDivNotVisble}>
                    <div>
                        <Typography
                            component={'div'}
                            className={classes.concatenationParameter}
                            gutterBottom>
                            Concatenation parameter:
                        </Typography>
                    </div>
                    <TextField
                        InputProps={{
                            classes: {
                                root: classes.inputRoot,
                                input: classes.inputLabel
                            }
                        }}
                        inputProps={{
                            maxLength: "1"
                        }}
                        label=""
                        variant="outlined"
                        size="small"
                        value={concatCharacter}
                        onChange={event => handleChangeConcatenatorParameter(event)}
                    />
                </div>
            </div>
        </form>
    )
}

export default React.memo(ConfigurationValueList)