//react
import React from 'react'

//redux
import { useDispatch, useSelector } from "react-redux"

//material ui hooks/components
import { makeStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import Typography from '@material-ui/core/Typography'


//style
const useStyles =
    makeStyles(theme => ({
        toolbarMargin: {
            ...theme.mixins.toolbar,
            '@media (min-width:600px)': {
                minHeight: '58px'
            },
            minHeight: '58px'
        },
        drawer: {
            backgroundColor: 'white',
            width: '13rem'
        },
        icon: {
            minWidth: '33px'
        },
        copyright: {
            flexGrow: 1,
            color: 'grey',
            textTransform: 'none',
            position: 'fixed',
            bottom: 0,
            width: 'inherit',
            fontSize: '0.6rem'
        }
    }))


//custom component function
const Navigation = () => {

    //hooks
    const classes = useStyles()
    const dispatch = useDispatch()
    const isNavigationMenuOpened =
        useSelector(
            state =>
                state
                    .common
                    .menu
                    .navigation
                    .isOpened)


    //event handlers
    const onBackdropClick = (event) =>
        dispatch(
            {
                type: 'TOGGLE_NAVIGATION_MENU',
                event: event
            })


    //custom component
    return (
        <React.Fragment>
            <Drawer
                variant="temporary"
                open={isNavigationMenuOpened}
                onBackdropClick={onBackdropClick}
                classes={{ paper: classes.drawer }}>
                <div
                    className={classes.toolbarMargin} />
                <List
                    disablePadding>
                    <ListItem
                        divider
                        button>
                        <ListItemIcon
                            className={classes.icon}>
                            <AddCircleOutlineIcon
                                fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                            disableTypography>
                            New Tracking
                        </ListItemText>
                        <ChevronRightIcon
                            fontSize="small" />
                    </ListItem>
                </List>
                <List
                    disablePadding>
                    <ListItem
                        divider
                        button>
                        <ListItemIcon
                            className={classes.icon}>
                            <FormatListBulletedIcon
                                fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                            disableTypography>
                            Tracking List
                        </ListItemText>
                        <ChevronRightIcon
                            fontSize="small" />
                    </ListItem>
                </List>
                <List
                    disablePadding>
                    <ListItem
                        divider
                        button>
                        <ListItemIcon
                            className={classes.icon}>
                            <ExitToAppIcon
                                fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                            disableTypography>
                            Sign Out
                        </ListItemText>
                    </ListItem>
                </List>
                <Typography
                    paragraph={true}
                    align="center"
                    variant="subtitle1"
                    className={classes.copyright}>
                    Copyright © {new Date().getFullYear()} | Ûptimal
                </Typography>
            </Drawer>
        </React.Fragment>
    )
}

export default Navigation