//react
import React from 'react'

//redux
import { useDispatch, useSelector } from "react-redux"

//material ui hooks/components
import { makeStyles } from '@material-ui/core/styles'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import SettingsIcon from '@material-ui/icons/Settings'


//style
const useStyles =
    makeStyles(theme => ({
        paper: {
            border: '1px solid #d3d4d5',
            top: '46px!important',
        },
        menu: {
            zIndex: '1301!important',
        },
        icon: {
            minWidth: '43px'
        }
    }))


const SettingsPopover = () => {

    //hooks
    const classes = useStyles()
    const dispatch = useDispatch()

    //event handlers
    const anchorElementSettingsPopover =
        useSelector(
            state =>
                state
                    .common
                    .header
                    .settingsPopover
                    .anchor)

    //additional data
    const options = [{
        icon: () => <SettingsIcon fontSize="small" />,
        text: 'Account settings'
    },
    {
        icon: () => <ExitToAppIcon fontSize="small" />,
        text: 'Sign Out'
    }]

    //custom component
    return (
        <Menu
            MenuListProps={{ disablePadding: true }}
            className={classes.menu}
            id="profile-menu"
            elevation={0}
            getContentAnchorEl={null}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            classes={{
                paper: classes.paper
            }}
            anchorEl={anchorElementSettingsPopover}
            keepMounted
            open={Boolean(anchorElementSettingsPopover)}
            onClose={() => dispatch({ type: 'TOGGLE_SETTINGS_POPOVER', event: null })}>
            {options.map((option, index) => (
                <MenuItem
                    key={index}
                    divider>
                    <ListItemIcon
                        className={classes.icon}>
                        {option.icon()}
                    </ListItemIcon>
                    <ListItemText primary={option.text} />
                </MenuItem>
            ))}
        </Menu>
    )
}

export default SettingsPopover
