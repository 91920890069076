//react
import React from 'react'

//redux
import { useDispatch, useSelector } from "react-redux"

//material ui hooks/components
import { makeStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import AppsTwoToneIcon from '@material-ui/icons/AppsTwoTone';
import FormatListBulletedTwoToneIcon from '@material-ui/icons/FormatListBulletedTwoTone';

import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'

//customized own components/material
import Header from './Header'


//style
const useStyles =
    makeStyles(theme => ({
        toolbarMargin: {
            ...theme.mixins.toolbar,
            '@media (min-width:600px)': {
                minHeight: '35px'
            },
            minHeight: '35px'
        },
        paperDrawer: {
            backgroundColor: '#f1f3f4',
            width: '75vw',
            overflow: 'hidden',
        },
        paperDrawerResize: {
            backgroundColor: '#f1f3f4',
            width: '75vw',
            overflow: 'hidden',
            transition: 'width 0.5s ease!important'
        },
        paperDrawerWhenOtherMenuOpened: {
            backgroundColor: '#f1f3f4',
            width: '100vw',
            overflow: 'hidden',
            transition: 'width 0.5s ease!important'
        },
        modalDrawer: {
            zIndex: '1301!important'
        },
        icon: {
            minWidth: '33px'
        },
        root: {
            flexGrow: 1,
        },
        paperMidGrid: {
            height: '17rem',
            width: '60vw',
            border: '1px solid transparent',
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: '#dadce0',
            boxShadow: '0 0 0 0',
            borderRadius: '8px',
            "&:hover": {
                boxShadow: '0 5px 5px -3px rgba(0,0,0,.2), 0 8px 10px 1px rgba(0,0,0,.14), 0 3px 14px 2px rgba(0,0,0,.12)'
            }
        },
        rootIconButton: {
            fontSize: '3rem',
            color: 'white',
            backgroundColor: '#ebeef0',
            borderRadius: '62px',
            padding: '1.5rem',
            marginBottom: '0.5rem',
            marginTop: '1rem',
            "&:hover": {
                backgroundColor: '#ebeef0',
            }
        },
        gridRoot: {
            height: '100%',
            cursor: 'pointer'
        },
        rootSvgIcon: {
            fontSize: '4.3rem'
        },
        rootTypo: {
            color: '#646262'
        },
        rootTypoMoreInfo: {
            marginBottom: 'auto',
            color: theme.palette.common.blue
        },
        rootTitleTypo: {
            fontSize: '1.2rem',
            fontWeight: '300',
            margin: '1rem'
        }
    }))


//custom component function
const RightMenu = () => {

    //hooks
    const classes = useStyles()
    const dispatch = useDispatch()

    //redux states
    const isNamingMenuCreateOpened =
        useSelector(
            state =>
                state
                    .naming
                    .menu
                    .create
                    .isOpened)

    const isValueMenuChooseOpened =
        useSelector(
            state =>
                state
                    .value
                    .menu
                    .choose
                    .isOpened)


    //event handlers
    const onBackdropClick = (event) =>
        dispatch(
            {
                type: 'TOGGLE_NAMING_MENU_CREATE',
                event: event
            })

    const onClickAddNaming =
        () =>
            dispatch({ type: 'TOGGLE_VALUE_MENU_CHOOSE' })

    //additional data
    const actions = [{
        title: 'Value Settings',
        icon: () => <FormatListBulletedTwoToneIcon classes={{ root: classes.rootSvgIcon }} />,
        text: 'Add a value for your naming...',
        tooltipTitle: 'values',
        tooltip: 'Values are the lowest level of a taxomony. A naming can have many values associated to it. As an example, a value would be "Ad Size" and its options could be "120x600", "160x600", "250x250"...'
    }]

    const isNamingMenuCreate100vw =
        document.querySelectorAll('#namingCreateDrawer >div.MuiPaper-root')[0] ?
            (document.querySelectorAll('#namingCreateDrawer >div.MuiPaper-root')[0]
                .offsetWidth === window.innerWidth) : false

    //custom component
    return (
        <React.Fragment>
            <Drawer
                id='namingCreateDrawer'
                transitionDuration={{ enter: 500, exit: 500 }}
                anchor="right"
                variant="temporary"
                open={isNamingMenuCreateOpened}
                onBackdropClick={onBackdropClick}
                PaperProps={{
                    classes: {
                        root:
                            isValueMenuChooseOpened ?
                                classes.paperDrawerWhenOtherMenuOpened :
                                (isNamingMenuCreate100vw ? classes.paperDrawerResize : classes.paperDrawer)
                    }
                }}>
                <Header />
                <div className={classes.toolbarMargin} />
                <Grid
                    container
                    className={classes.root}
                    spacing={2}>
                    <Grid
                        item xs={12}>
                        <Grid
                            container
                            direction="column"
                            alignItems="center"
                            justify="center"
                            spacing={2}>
                            {actions.map((action, index) => (
                                <Grid
                                    key={index}
                                    item>
                                    <Paper
                                        className={classes.paperMidGrid}
                                        onClick={onClickAddNaming}>
                                        <Typography
                                            variant="h6"
                                            classes={{ root: classes.rootTitleTypo }}>
                                            {action.title}
                                        </Typography>
                                        <Grid
                                            classes={{ root: classes.gridRoot }}
                                            container
                                            direction="column"
                                            alignItems="center"
                                            align="center"
                                            justify="center"
                                            spacing={4}>
                                            <IconButton
                                                classes={{ root: classes.rootIconButton }}
                                                aria-label="copy">
                                                {action.icon()}
                                            </IconButton>
                                            <Typography
                                                variant="body2"
                                                classes={{ root: classes.rootTypo }}>
                                                {action.text}
                                            </Typography>
                                            <Tooltip
                                                title={
                                                    <React.Fragment>
                                                        <Typography
                                                            color="inherit">
                                                            What are {action.tooltipTitle}?
                                                        </Typography>
                                                        {action.tooltip}
                                                    </React.Fragment>
                                                }>
                                                <Typography
                                                    variant="caption"
                                                    classes={{ root: classes.rootTypoMoreInfo }}>
                                                    More Info
                                                </Typography>
                                            </Tooltip>
                                        </Grid>
                                    </Paper>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
            </Drawer>
        </React.Fragment >
    )
}

export default RightMenu