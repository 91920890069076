import React from "react"
import GoogleLogin from 'react-google-login'

//redux
import { useDispatch, useSelector } from "react-redux"

//material
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'

//customized own components
import background from '../../../assets/background-login.svg'
import lockLoginImage from '../../../assets/login-lock-image.svg'
import logo from '../../../assets/upbuild-logo.svg'

//style
const useStyles = makeStyles({

    loginCard: {
        minWidth: '16rem',
        minHeight: '24rem',
        boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)'
    },
    lockLoginImage: {
        height: 0,
        paddingTop: '96.25%',
        transform: 'scale(0.7)',
        marginBottom: '1.7rem'
    },
    gridContainer: {
        height: '100vh',
        backgroundImage: `url(${background})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat'
    },
    loginForm: {
        justifyContent: 'center',
        display: 'flex'
    },
    divLoginForm: {
        display: 'flex',
        alignItems: 'center'
    },
    copyright: {
        justifyContent: 'center',
        position: 'fixed',
        bottom: 0,
        left: '50%',
        transform: 'translate(-50%, 0)',
        marginBottom: '2.5rem',
        fontSize: '1.2rem',
        fontWeight: '800',
        color: 'rgb(0 0 0 / 58%)'
    },
    logInButtonWrapper: {
        display: 'flex',
        justifyContent: 'center'
    },
    helpLinkWrapper: {
        justifyContent: 'center',
        marginTop: '1rem'
    },
    helpLink: {
        fontSize: '0.7rem'
    },
    loginButton: {
        outline: 0,
        boxShadow: 'rgb(0 0 0 / 24%) 0px 4px 4px 0px, rgb(0 0 0 / 24%) 0px 0px 6px 0px!important',
        borderRadius: '4px'
    },
    cardContent: {
        marginTop: '-2rem'
    },
    topRightButton: {
        float: 'right',
        marginTop: '2rem',
        marginRight: '2rem'
    },
    topLeftLogo: {
        float: 'left',
        marginTop: '2rem',
        marginLeft: '2rem',
        width: '10rem'
    },
    headerContainer: {
        height: '2rem'
    }
})


const Login = () => {

    const classes = useStyles();
    const dispatch = useDispatch()

    //redux states
    const googleLoginClientId =
        useSelector(
            state =>
                state
                    .user
                    .googleLoginClientId)

    console.log(process.env.NODE_ENV)

    const handleLogin =
        async googleData => {
            if (!googleData.error) {
                const res =
                    await fetch(`${process.env.REACT_APP_API_GATEWAY}/hello?access_token=${googleData.tokenId}`)
                console.log(await res.text())
                // store returned user somehow
            } else {
                console.log(googleData.error)
            }
        }


    return (
        <Grid
            container
            spacing={0}
            className={classes.gridContainer}>
            <Grid
                item xs={6}
                className={classes.headerContainer}>
                <img
                    src={logo}
                    alt="logo"
                    className={classes.topLeftLogo} />
            </Grid>
            <Grid
                item xs={6}
                className={classes.headerContainer}>
                <Button
                    variant="outlined"
                    color="primary"
                    className={classes.topRightButton}>
                    Documentation
                </Button>
            </Grid>

            <Grid
                item xs={12}>
                <Grid
                    item xs={12}
                    className={classes.loginForm}>
                    <div
                        className={classes.divLoginForm}>
                        <Card
                            justify="center"
                            className={classes.loginCard}
                            variant="outlined">
                            <CardMedia
                                className={classes.lockLoginImage}
                                image={lockLoginImage}
                                title="lock-login-upbuild"
                            />
                            <CardContent
                                className={classes.cardContent}>

                                <div
                                    className={classes.logInButtonWrapper}>
                                    <GoogleLogin
                                        className={classes.loginButton}
                                        clientId={googleLoginClientId}
                                        buttonText="Log in with Google"
                                        theme="dark"
                                        onSuccess={handleLogin}
                                        onFailure={handleLogin}
                                        cookiePolicy={'single_host_origin'}
                                    />
                                </div>
                            </CardContent>
                            <CardActions
                                className={classes.helpLinkWrapper}>
                                <Link
                                    href="https://support.google.com/accounts/answer/27441#zippy="
                                    target="_blank"
                                    rel="noreferrer"
                                    variant="body2"
                                    className={classes.helpLink}>
                                    No Google account linked?
                                </Link>
                            </CardActions>
                        </Card>
                    </div>
                </Grid>
            </Grid>

            <Grid
                item xs={12}>
                <Typography
                    variant="h5"
                    color="initial"
                    className={classes.copyright}>
                    Copyright © {new Date().getFullYear()} | Ûptimal
                </Typography>
            </Grid>
        </Grid>
    )
}

export default Login