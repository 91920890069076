const initialState = {
    menu: {
        choose: {
            isOpened: false,
            toggledTimes: 0
        },
        create: {
            isOpened: false,
            toggledTimes: 0,
            currentStep: 0,
            stepType: null,
            valueList: {
                stepConfigurationOptions: {
                    options: []
                },
                stepConfigurationSettings: {
                    concatenatorCharacter: '',
                    optional: false,
                    multiple: false
                }
            },
            valueFreeText: {
                stepConfigurationOptions: {
                    characters: [],
                    character: false,
                    letter: false,
                    number: false
                },
                stepConfigurationSettings: {
                    optional: false,
                    lowerUpperCase: false,
                    lowerUpperCaseOption: 'lowercase'
                }
            },
            valueDatePicker: {
                stepFormatOptions: {
                    elem1: '',
                    elem2: '',
                    elem3: '',
                    allowPastDates: false,
                    removeLeadingZeros: false,
                    customDateSeparator: false,
                    separatorCharacter: ''
                },
                stepConfigurationSettings: {
                    optional: false
                }
            }
        }
    },
    table: {
        sortColumnName: 'calories',
        sortColumnOrder: 'asc',
        checkbox: {
            header: {
                selected: false
            },
            body: {
                selected: []
            }
        },
        column: [
            { name: 'name', numeric: false, disablePadding: true, label: 'Dessert (100g serving)' },
            { name: 'calories', numeric: true, disablePadding: false, label: 'Calories' },
            { name: 'fat', numeric: true, disablePadding: false, label: 'Fat (g)' },
            { name: 'carbs', numeric: true, disablePadding: false, label: 'Carbs (g)' },
            { name: 'protein', numeric: true, disablePadding: false, label: 'Protein (g)' },
        ],
        row: [
            { name: 'Cupcake', calories: 305, fat: 3.7, carbs: 67, protein: 4.3 },
            { name: 'Donut', calories: 452, fat: 25.0, carbs: 51, protein: 4.9 },
            { name: 'Eclair', calories: 262, fat: 16.0, carbs: 24, protein: 6.0 },
            { name: 'Frozen yoghurt', calories: 159, fat: 6.0, carbs: 24, protein: 4.0 },
            { name: 'Gingerbread', calories: 356, fat: 16.0, carbs: 49, protein: 3.9 },
            { name: 'Honeycomb', calories: 408, fat: 3.2, carbs: 87, protein: 6.5 },
            { name: 'Ice cream sandwich', calories: 237, fat: 9.0, carbs: 37, protein: 4.3 },
            { name: 'Jelly Bean', calories: 375, fat: 0.0, carbs: 94, protein: 0.0 },
            { name: 'KitKat', calories: 518, fat: 26.0, carbs: 65, protein: 7.0 },
            { name: 'Lollipop', calories: 392, fat: 0.2, carbs: 98, protein: 0.0 },
            { name: 'Marshmallow', calories: 318, fat: 0, carbs: 81, protein: 2.0 },
            { name: 'Nougat', calories: 360, fat: 19.0, carbs: 9, protein: 37.0 },
            { name: 'Oreo', calories: 437, fat: 18.0, carbs: 63, protein: 4.0 }
        ],
        pagination: {
            currentPage: 0,
            rows: 5,
            rowOptions: [5, 10, 25, 50, 100, 200]
        },
        search: {
            isSearched: false,
            row: []
        }
    }
}

const variable = (state = initialState, action) => {

    let updatedState = { ...state }

    switch (action.type) {

        case 'TOGGLE_VALUE_MENU_CHOOSE':
            if (state.menu.choose.isOpened) {
                updatedState.menu.choose.isOpened = false

            } else {
                updatedState.menu.choose.isOpened = true
            }

            updatedState.menu.choose.toggledTimes++

            return updatedState


        case 'TOGGLE_VALUE_MENU_CREATE':
            if (state.menu.create.isOpened) {
                updatedState.menu.create.isOpened = false

            } else {
                updatedState.menu.create.isOpened = true
            }

            updatedState.menu.create.toggledTimes++

            return updatedState


        case 'SEARCH_VALUE_TABLE':
            if (action.event.target.value !== "") {

                let columnNames

                let search = state.table.row.filter(row => {
                    columnNames = Object.keys(row)
                    return columnNames.some(columnName => {
                        return row[columnName]
                            .toString()
                            .toLowerCase()
                            .includes(action
                                .event
                                .target
                                .value
                                .toLowerCase())
                    })
                })

                updatedState.table.pagination.currentPage = 0
                updatedState.table.search.isSearched = true
                updatedState.table.search.row = [...search]
                return updatedState

            } else {
                updatedState.table.pagination.currentPage = 0
                updatedState.table.search.isSearched = false
                return updatedState
            }


        case 'CLICK_BODY_CHECKBOX_VALUE_TABLE':
            const selectedCheckboxes =
                state.table.checkbox.body.selected

            const selectedIndex =
                selectedCheckboxes.indexOf(
                    action.rowName)

            let newSelected = []

            if (selectedIndex === -1) {
                newSelected = newSelected.concat(
                    selectedCheckboxes, action.rowName)

            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(
                    selectedCheckboxes.slice(1))

            } else if (selectedIndex === selectedCheckboxes.length - 1) {
                newSelected = newSelected.concat(
                    selectedCheckboxes.slice(0, -1))

            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(
                    selectedCheckboxes.slice(
                        0, selectedIndex),
                    selectedCheckboxes.slice(
                        selectedIndex + 1),
                )
            }

            updatedState.checkbox.body.selected = newSelected
            return updatedState


        case 'CLICK_HEADER_CHECKBOX_VALUE_TABLE':
            if (action.event.target.checked) {
                const allRowsSelected =
                    state.table.row.map((n) => n.name);
                updatedState.checkbox.body.selected = allRowsSelected

            } else {
                updatedState.checkbox.body.selected = []
            }

            return updatedState


        case 'SORT_VALUE_TABLE':
            const newOrderType =
                state.table.sortColumnName === action.columnName &&
                    state.table.sortColumnOrder === 'asc' ? 'desc' : 'asc'
            updatedState.table.sortColumnName = action.columnName
            updatedState.table.sortColumnOrder = newOrderType
            return updatedState


        case 'CHANGE_PAGE_VALUE_TABLE':
            updatedState
                .table
                .pagination
                .currentPage =
                action.page

            return updatedState


        case 'CHANGE_ROWS_PER_PAGE_VALUE_TABLE':
            updatedState
                .table
                .pagination
                .currentPage = 0

            updatedState
                .table
                .pagination
                .rows =
                parseInt(
                    action
                        .event
                        .target
                        .value, 10)

            return updatedState


        case 'NEXT_STEP_VALUE_MENU_CREATE':
            updatedState
                .menu
                .create
                .currentStep++

            return updatedState


        case 'PREVIOUS_STEP_VALUE_MENU_CREATE':
            updatedState
                .menu
                .create
                .currentStep--

            return updatedState


        case 'TYPE_SELECTED_VALUE_MENU_CREATE':
            updatedState
                .menu
                .create
                .stepType
                = action.stepType

            updatedState
                .menu
                .create
                .currentStep++

            return updatedState


        case 'REMOVE_ALL_OPTIONS_CONFIGURATION_STEP_VALUE_MENU_CREATE':
            updatedState
                .menu
                .create
                .valueList
                .stepConfigurationOptions
                .options = []

            return {
                ...updatedState,
                menu: {
                    ...updatedState
                        .menu,
                    create: {
                        ...updatedState
                            .menu
                            .create,
                        valueList: {
                            ...updatedState
                                .menu
                                .create
                                .valueList,
                            stepConfigurationOptions: {
                                ...updatedState
                                    .menu
                                    .create
                                    .valueList
                                    .stepConfigurationOptions,
                                options:
                                    [...updatedState
                                        .menu
                                        .create
                                        .valueList
                                        .stepConfigurationOptions.options]
                            }
                        }
                    }
                }
            }


        case 'REMOVE_ALL_CHARACTERS_CONFIGURATION_STEP_VALUE_MENU_CREATE':
            updatedState
                .menu
                .create
                .valueFreeText
                .stepConfigurationOptions
                .characters = []

            return {
                ...updatedState,
                menu: {
                    ...updatedState
                        .menu,
                    create: {
                        ...updatedState
                            .menu
                            .create,
                        valueFreeText: {
                            ...updatedState
                                .menu
                                .create
                                .valueFreeText,
                            stepConfigurationOptions: {
                                ...updatedState
                                    .menu
                                    .create
                                    .valueFreeText
                                    .stepConfigurationOptions,
                                characters:
                                    [...updatedState
                                        .menu
                                        .create
                                        .valueFreeText
                                        .stepConfigurationOptions
                                        .characters]
                            }
                        }
                    }
                }
            }


        case 'ADD_OPTION_CONFIGURATION_STEP_VALUE_MENU_CREATE':
            updatedState
                .menu
                .create
                .valueList
                .stepConfigurationOptions
                .options =
                [...new Set(
                    [...updatedState
                        .menu
                        .create
                        .valueList
                        .stepConfigurationOptions
                        .options,
                    ...action.option])]

            return {
                ...updatedState,
                menu: {
                    ...updatedState
                        .menu,
                    create: {
                        ...updatedState
                            .menu
                            .create,
                        valueList: {
                            ...updatedState
                                .menu
                                .create
                                .valueList,
                            stepConfigurationOptions: {
                                ...updatedState
                                    .menu
                                    .create
                                    .valueList
                                    .stepConfigurationOptions,
                                options:
                                    [...updatedState
                                        .menu
                                        .create
                                        .valueList
                                        .stepConfigurationOptions
                                        .options]
                            }
                        }
                    }
                }
            }


        case 'ADD_CHARACTER_CONFIGURATION_STEP_VALUE_MENU_CREATE':
            updatedState
                .menu
                .create
                .valueFreeText
                .stepConfigurationOptions
                .characters =
                [...new Set(
                    [...updatedState
                        .menu
                        .create
                        .valueFreeText
                        .stepConfigurationOptions
                        .characters,
                    ...action.character])]

            return {
                ...updatedState,
                menu: {
                    ...updatedState
                        .menu,
                    create: {
                        ...updatedState
                            .menu
                            .create,
                        valueFreeText: {
                            ...updatedState
                                .menu
                                .create
                                .valueFreeText,
                            stepConfigurationOptions: {
                                ...updatedState
                                    .menu
                                    .create
                                    .valueFreeText
                                    .stepConfigurationOptions,
                                characters:
                                    [...updatedState
                                        .menu
                                        .create
                                        .valueFreeText
                                        .stepConfigurationOptions
                                        .characters]
                            }
                        }
                    }
                }
            }


        case 'REMOVE_OPTION_CONFIGURATION_STEP_VALUE_MENU_CREATE':
            let options =
                updatedState
                    .menu
                    .create
                    .valueList
                    .stepConfigurationOptions
                    .options
                    .filter(option =>
                        option !== action.option)

            return {
                ...updatedState,
                menu: {
                    ...updatedState
                        .menu,
                    create: {
                        ...updatedState
                            .menu
                            .create,
                        valueList: {
                            ...updatedState
                                .menu
                                .create
                                .valueList,
                            stepConfigurationOptions: {
                                ...updatedState
                                    .menu
                                    .create
                                    .valueList
                                    .stepConfigurationOptions,
                                options:
                                    [...options]
                            }
                        }
                    }
                }
            }


        case 'REMOVE_CHARACTER_CONFIGURATION_STEP_VALUE_MENU_CREATE':
            let characters =
                updatedState
                    .menu
                    .create
                    .valueFreeText
                    .stepConfigurationOptions
                    .characters
                    .filter(character =>
                        character !== action.character)

            return {
                ...updatedState,
                menu: {
                    ...updatedState
                        .menu,
                    create: {
                        ...updatedState
                            .menu
                            .create,
                        valueFreeText: {
                            ...updatedState
                                .menu
                                .create
                                .valueFreeText,
                            stepConfigurationOptions: {
                                ...updatedState
                                    .menu
                                    .create
                                    .valueFreeText
                                    .stepConfigurationOptions,
                                characters:
                                    [...characters]
                            }
                        }
                    }
                }
            }


        case 'CHANGE_CONCATENATOR_CHARACTER_CONFIGURATION_STEP_VALUE_MENU_CREATE':
            state
                .menu
                .create
                .valueList
                .stepConfigurationSettings
                .concatenatorCharacter
                = action.character
            return updatedState


        case 'CHANGE_DATE_SEPARATOR_CHARACTER_CONFIGURATION_STEP_VALUE_MENU_CREATE':
            state
                .menu
                .create
                .valueDatePicker
                .stepFormatOptions
                .separatorCharacter
                = action.character
            return updatedState


        case 'SELECT_ELEMENT_DATE_CONFIGURATION_STEP_VALUE_MENU_CREATE':
            state
                .menu
                .create
                .valueDatePicker
                .stepFormatOptions
            [action.element]
                = action.value
            return updatedState


        case 'TOGGLE_CHECKBOX_CONFIGURATION_STEP_VALUE_MENU_CREATE':
            if (state
                .menu
                .create
            [action.valueType]
            [action.block]
            [action.setting]) {
                state
                    .menu
                    .create
                [action.valueType]
                [action.block]
                [action.setting] = false

            } else {
                state
                    .menu
                    .create
                [action.valueType]
                [action.block]
                [action.setting] = true
            }

            return updatedState


        case 'CHANGE_CASE_CONFIGURATION_STEP_VALUE_MENU_CREATE':
            updatedState
                .menu
                .create
                .valueFreeText
                .stepConfigurationSettings
                .lowerUpperCaseOption = action.case

            return updatedState


        default:
            return state
    }
}

export default variable