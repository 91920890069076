import { combineReducers } from 'redux'
import common from './common'
import campaign from './campaign'
import blueprint from './blueprint'
import naming from './naming'
import value from './value'
import user from './user'

export default combineReducers({
    common,
    campaign,
    blueprint,
    naming,
    value,
    user
})