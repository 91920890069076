//react
import React from 'react'

//redux
import { useDispatch } from "react-redux"

//material ui hooks/components
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Button from '@material-ui/core/Button'
import { Link } from 'react-router-dom'
import MenuIcon from '@material-ui/icons/Menu'
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import IconButton from '@material-ui/core/IconButton'
import NotificationsIcon from '@material-ui/icons/Notifications'
import Badge from '@material-ui/core/Badge'
import HelpIcon from '@material-ui/icons/Help'
import MoreVertIcon from '@material-ui/icons/MoreVert'

//customized own components/material
import ProfilePopover from './SettingsPopover'
import avatar from '../../../../assets/avatar-full.jpg'


//style
const useStyles =
    makeStyles(theme => ({
        toolbar: {
            minHeight: '48px'
        },
        logoContainer: {
            padding: 0,
            "&:hover": {
                backgroundColor: 'transparent'
            }
        },
        drawerIconContainer: {
            marginRigt: 'auto',
            "&:hover": {
                backgroundColor: 'transparent'
            }
        },
        drawerIcon: {
            height: '24px',
            width: '30px',
            color: 'white'

        },
        appBar: {
            zIndex: theme.zIndex.modal,
            minHeight: '48px',
        },
        titleFirst: {
            ...theme.typography.title,
            flexGrow: 1,
            color: 'white',
            marginLeft: '9px',
            textTransform: 'none',
            fontWeight: '450'

        },
        titleSecond: {
            ...theme.typography.title,
            flexGrow: 1,
            color: 'white',
            textTransform: 'none'

        },
        avatar: {
            width: '30px',
            height: '30px',
            fontSize: '1rem'
        },
        iconsAppBarRight: {
            marginLeft: 'auto',
            display: 'flex',
            marginRight: '12px'
        },
        badge: {
            height: '20px',
            minWidth: '20px',
            fontSize: '0.70rem',
            border: `2px solid ${theme.palette.common.blue}`
        }
    }))


//custom component function
const Header = () => {

    //hooks
    const classes = useStyles()
    const dispatch = useDispatch()

    //event handlers
    const onClickLeftMenuHamburguer =
        () =>
            dispatch({ type: 'TOGGLE_NAVIGATION_MENU' })

    const onClickHeaderAccountSettingsThreeDots =
        event =>
            dispatch({
                type: 'TOGGLE_SETTINGS_POPOVER',
                event: event.currentTarget
            })

    //custom component
    return (
        <React.Fragment>
            <AppBar
                position="fixed"
                className={classes.appBar}
                color="primary">
                <Toolbar
                    className={classes.toolbar}
                    disableGutters>
                    <IconButton
                        className={classes.drawerIconContainer}
                        onClick={onClickLeftMenuHamburguer}
                        disableRipple>
                        <MenuIcon
                            className={classes.drawerIcon}>
                        </MenuIcon>
                    </IconButton>
                    <Button
                        component={Link} to="/"
                        className={classes.logoContainer}
                        disableRipple>
                        <Typography
                            variant="h6"
                            className={classes.titleFirst}>
                            Ûp
                            </Typography>
                        <Typography
                            variant="h6"
                            className={classes.titleSecond}>
                            build
                            </Typography>
                    </Button>
                    <div className={classes.iconsAppBarRight}>
                        <IconButton
                            aria-label="help"
                            color="inherit">
                            <HelpIcon
                                fontSize="small" />
                        </IconButton>
                        <IconButton
                            aria-label="notifications"
                            color="inherit">
                            <Badge
                                classes={{ badge: classes.badge }}
                                badgeContent={4}
                                variant="standard"
                                overlap="rectangle"
                                color="error">
                                <NotificationsIcon
                                    fontSize="small" />
                            </Badge>
                        </IconButton>
                        <IconButton
                            aria-label="more"
                            color="inherit"
                            onClick={onClickHeaderAccountSettingsThreeDots}>
                            <MoreVertIcon
                                fontSize="small" />
                        </IconButton>

                        <IconButton
                            disabled={true}
                            variant="contained">
                            <Avatar
                                className={classes.avatar}
                                alt="logged in user"
                                src={avatar} />
                        </IconButton>
                    </div>
                    <ProfilePopover />
                </Toolbar>
            </AppBar>
        </React.Fragment >
    )
}

export default Header