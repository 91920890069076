//react
import React from 'react'

//customized own components
import SectionTitle from '../Common/Section/Title'
import Table from './Table/Table'
import NavigationMenu from '../Common/Menu/Navigation'
import BlueprintMenuCreate from '../../ui/Blueprint/Create/Menu'


//custom component function
const Blueprint = () => {

    //custom component
    return (
        <React.Fragment>
            <SectionTitle />
            <Table />
            <NavigationMenu />
            <BlueprintMenuCreate />
        </React.Fragment >
    )
}

export default Blueprint