//react
import React from 'react'

//redux
import { useDispatch, useSelector } from "react-redux"

//material ui hooks/components
import { makeStyles } from '@material-ui/core/styles'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import StepContent from '@material-ui/core/StepContent'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import SettingsIcon from '@material-ui/icons/Settings'
import Typography from '@material-ui/core/Typography'

//customized own components/material
import ValueType from './Type'
import ValueConfiguration from './Configuration/ConfigurationValueList'
import ValueFreeText from './Configuration/ConfigurationFreeText'
import ValueDatePicker from './Configuration/ConfigurationDatePicker'
import TextFieldsIcon from '@material-ui/icons/TextFields'


//style
const useStyles =
    makeStyles((theme) => ({
        root: {
            width: '100%',
        },
        button: {
            marginTop: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        actionsContainer: {
            marginBottom: theme.spacing(2),
        },
        resetContainer: {
            padding: theme.spacing(3),
        },
        stepperRoot: {
            padding: '2rem',
            margin: '1rem',
            background: 'none',
            border: 'none',
            width: '33rem'
        },
        paperMidGrid: {
            width: '40rem',
            border: '1px solid transparent',
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: '#dadce0',
            boxShadow: '0 0 0 0',
            borderRadius: '8px',
        },
        valueTypes: {
            display: 'flex',
            marginTop: '1rem'
        },
        alternativeLabel: {
            backgroundColor: theme.palette.common.blue,
            borderRadius: '1rem',
            color: 'white',
            padding: '0.2rem'
        }
    }));


//custom component function
const CustomStepper = () => {

    const classes = useStyles();
    const dispatch = useDispatch()


    //redux states
    const valueMenuCreateStepType =
        useSelector(
            state =>
                state
                    .value
                    .menu
                    .create
                    .stepType)

    const valueStepperActiveStep =
        useSelector(
            state =>
                state
                    .value
                    .menu
                    .create
                    .currentStep)


    //event handlers
    const handleNextStep =
        React.useCallback(
            () => {
                dispatch(
                    {
                        type: 'NEXT_STEP_VALUE_MENU_CREATE',
                    })
            }, [dispatch])


    const handlePreviousStep =
        React.useCallback(
            () => {
                dispatch(
                    {
                        type: 'PREVIOUS_STEP_VALUE_MENU_CREATE',
                    })
            }, [dispatch])


    //additional data
    const configurationType = () => {
        switch (valueMenuCreateStepType) {
            case 'list':
                return <ValueConfiguration key="valueConfigurationValueListStep" />

            case 'freeText':
                return <ValueFreeText key="valueConfigurationFreeTextStep" />

            case 'date':
                return <ValueDatePicker key="valueConfigurationDatePickerStep" />

            default:
                break
        }
    }

    //step name optional stepper label
    const optionalStepName = () => {

        switch (valueMenuCreateStepType) {

            case 'list':
                return 'Value List'
            case 'freeText':
                return 'Free Text'
            case 'date':
                return 'Date Picker'
            default:

        }
    }

    const steps = [
        {
            id: 'stepValueType',
            title: 'Select value type',
            icon: () => <TextFieldsIcon classes={{ root: classes.alternativeLabel }} />,
            typeSelected: (<Typography variant="caption">{optionalStepName()}</Typography>),
            content: <ValueType key="valueTypeStep" />
        },
        {
            id: 'stepValueConfiguration',
            title: 'Configure value',
            icon: () => <SettingsIcon classes={{ root: classes.alternativeLabel }} />,
            typeSelected: null,
            content: configurationType()
        }
    ]

    //custom component
    return (
        <div
            className={classes.root}>
            <Paper
                className={classes.paperMidGrid}>
                <Stepper
                    activeStep={valueStepperActiveStep}
                    orientation="vertical"
                    classes={{ root: classes.stepperRoot }}>
                    {steps.map((step, index) => {

                        return <Step
                            key={`step-${index}`}>
                            <StepLabel
                                StepIconComponent={step.icon}
                                optional={step.typeSelected}>
                                {step.title}
                            </StepLabel>
                            <StepContent
                                key={`stepContent-${index}`}>
                                <div
                                    key="valueTypes"
                                    className={classes.valueTypes}>
                                    <React.Fragment>
                                        {step.content}
                                    </React.Fragment>
                                </div>
                                <Box
                                    key="formExtraButtons"
                                    className={classes.actionsContainer}
                                    display={valueStepperActiveStep === 0 ? 'none' : 'block'}>
                                    <div>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => handleNextStep()}
                                            className={classes.button}>
                                            {valueStepperActiveStep === steps.length - 1 ? 'Save' : 'Next'}
                                        </Button>
                                        <Button
                                            disabled={valueStepperActiveStep === 0}
                                            onClick={handlePreviousStep}
                                            className={classes.button}>
                                            Back
                                        </Button>
                                    </div>
                                </Box>
                            </StepContent>
                        </Step>
                    })}
                </Stepper>
            </Paper>
        </div>
    )
}

export default CustomStepper