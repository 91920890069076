//react
import React from 'react'

//material ui hooks/components
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Typography from '@material-ui/core/Typography'

//customized own components
import campaign from '../../../../assets/campaigns-title.svg'

//style
const useStyles =
    makeStyles((theme) => ({
        root: {
            display: 'flex',
            marginLeft: '30px',
            marginTop: '15px',
            width: '35em',
            height: '5em',
            backgroundColor: '#fafafa'
        },
        details: {
            display: 'flex',
            flexDirection: 'column',
        },
        content: {
            flex: '1 0 auto',
            paddingTop: '8px'
        },
        cover: {
            backgroundSize: 'contain',
            width: '8%',
            marginBottom: '9px'
        },
        mainTitle: {
            fontSize: '1.3rem',
            fontWeight: '500'
        }
    }))


//custom component function
const SectionTitle = () => {
    const classes = useStyles()

    return (
        <Card className={classes.root}>
            <CardMedia
                className={classes.cover}
                image={campaign}
                title="Live from space album cover" />
            <div
                className={classes.details}>
                <CardContent
                    className={classes.content}>
                    <Typography
                        component="h2"
                        variant="h5"
                        className={classes.mainTitle}>
                        Campaigns5
                    </Typography>
                    <Typography
                        variant="subtitle1"
                        color="textSecondary">
                        List
                    </Typography>
                </CardContent>
            </div>
        </Card>
    )
}

export default SectionTitle