//react
import React from 'react'

//router
import { Route, Redirect } from 'react-router-dom'

//redux
import { useDispatch, useSelector } from "react-redux"

const PrivateRoute = ({ component: Component, ...rest }) => {

    //const dispatch = useDispatch()

    //redux states
    const isUserLoggedIn =
        useSelector(
            state =>
                state
                    .user
                    .isLoggedIn)

    return (
        <Route
            {...rest}
            render={(props) =>
                isUserLoggedIn ?
                    (<Component {...props} />) :
                    (<Redirect to="/login" />)}
        />
    )
}

export default PrivateRoute

