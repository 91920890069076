const initialState = {
    googleLoginClientId: '123149866610-4gv6snnpf74amv83vdl627lscpelovg4.apps.googleusercontent.com',
    isLoggedIn: true
}


const user = (state = initialState, action) => {

    let updatedState = { ...state }

    switch (action.type) {

        case 'TOGGLE_SETT--INGS_POPOVER':
            if (action.event &&
                !state.header.settingsPopover.isOpened) {
                updatedState.header.settingsPopover.isOpened = true
                updatedState.header.settingsPopover.elementToAnchorTheMenu = action.event

            } else {
                updatedState.header.settingsPopover.isOpened = false
                updatedState.header.settingsPopover.elementToAnchorTheMenu = null
            }

            return updatedState

        case 'TOGGLE_NAV--IGATION_MENU':
            if (state.menu.navigation.isOpened) {
                updatedState.menu.navigation.isOpened = false

            } else {
                updatedState.menu.navigation.isOpened = true
            }

            return updatedState

        default:
            return state
    }
}

export default user