//react
import React from 'react'

//redux
import { useDispatch, useSelector } from "react-redux"

//material ui hooks/components
import { makeStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import Grid from '@material-ui/core/Grid'
import FormatListBulletedTwoToneIcon from '@material-ui/icons/FormatListBulletedTwoTone';

import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'

//customized own components/material
import Header from './Header'
import VerticalLinearStepper from './Stepper/Stepper'


//style
const useStyles =
    makeStyles(theme => ({
        paperDrawer: {
            backgroundColor: '#f1f3f4',
            width: '45.5rem',
            overflow: 'scroll'
        },
        modalDrawer: {
            zIndex: '1301!important'
        },
        icon: {
            minWidth: '33px'
        },
        root: {
            flexGrow: 1,
        },
        paper: {
            height: '17rem',
            width: '57rem',
            border: '1px solid transparent',
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: '#dadce0',
            boxShadow: '0 0 0 0',
            borderRadius: '8px',
            "&:hover": {
                boxShadow: '0 5px 5px -3px rgba(0,0,0,.2), 0 8px 10px 1px rgba(0,0,0,.14), 0 3px 14px 2px rgba(0,0,0,.12)'
            }
        },
        rootIconButton: {
            fontSize: '3rem',
            color: 'white',
            backgroundColor: '#ebeef0',
            borderRadius: '62px',
            padding: '1.5rem',
            marginBottom: '0.5rem',
            marginTop: '1rem',
            "&:hover": {
                backgroundColor: '#ebeef0',
            }
        },
        paperMidGrid: {
            height: '17rem',
            width: '60vw',
            border: '1px solid transparent',
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: '#dadce0',
            boxShadow: '0 0 0 0',
            borderRadius: '8px',
            "&:hover": {
                boxShadow: '0 5px 5px -3px rgba(0,0,0,.2), 0 8px 10px 1px rgba(0,0,0,.14), 0 3px 14px 2px rgba(0,0,0,.12)'
            }
        },
        upperGridroot: {
            flexGrow: 1,
        },
        gridRoot: {
            overflowY: 'visible',
            margin: '3rem'
        },
        rootSvgIcon: {
            fontSize: '2.5rem',
            backgroundColor: '#8080807a',
            borderRadius: '3rem',
            padding: '0.5rem',
            color: 'white'
        },
        rootTypo: {
            color: '#646262'
        },
        rootTypoMoreInfo: {
            marginBottom: 'auto',
            color: theme.palette.common.blue
        },
        rootTitleTypo: {
            fontSize: '1.2rem',
            fontWeight: '300',
            margin: '1rem'
        }
    }))


//custom component function
const RightMenu = () => {

    //hooks
    const classes = useStyles()
    const dispatch = useDispatch()

    //redux states
    const isValueMenuCreateOpened =
        useSelector(
            state =>
                state
                    .value
                    .menu
                    .create
                    .isOpened)


    //event handlers
    const onBackdropClick = (event) =>
        dispatch(
            {
                type: 'TOGGLE_VALUE_MENU_CREATE',
                event: event
            })

    const onClickAddValue =
        () =>
            dispatch({ type: 'TOGGLE_VALUE_MENU_CREATE' })

    //additional data
    const actions = [{
        title: 'Naming Settings',
        icon: () => <FormatListBulletedTwoToneIcon classes={{ root: classes.rootSvgIcon }} />,
        text: 'Choose a naming for your blueprint...',
        tooltipTitle: 'namings',
        tooltip: 'Namings are sets of information units (variables) around a concept. As an example, a naming would be "Ad Name" and its variables could be "dimensions", "target", "device"...'
    }]

    //custom component
    return (
        <React.Fragment>
            <Drawer
                transitionDuration={{ enter: 500, exit: 500 }}
                anchor="right"
                variant="temporary"
                open={isValueMenuCreateOpened}
                onBackdropClick={onBackdropClick}
                classes={{
                    paper: classes.paperDrawer
                }}>
                <Header />
                <div className={classes.toolbarMargin} />
                <Grid
                    container
                    classes={{ root: classes.gridRoot }}
                    className={classes.upperGridroot}
                    spacing={2}>
                    <Grid
                        item xs={12}>
                        <Grid
                            container
                            direction="column"
                            alignItems="center"
                            justify="center"
                            spacing={2}>
                            <VerticalLinearStepper />
                        </Grid>
                    </Grid>
                </Grid>
            </Drawer>
        </React.Fragment >
    )
}

export default RightMenu