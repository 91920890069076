//react
import React from 'react'

//redux
import { useDispatch, useSelector } from "react-redux"

//material ui hooks/components
import { makeStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'

import Table from '@material-ui/core/Table'
import TableContainer from '@material-ui/core/TableContainer'
import TablePagination from '@material-ui/core/TablePagination'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import AddIcon from '@material-ui/icons/Add'
import Fab from '@material-ui/core/Fab'

//customized own components
import TableHead from './Header'
import TableRows from './Rows'
import TableToolbar from './Toolbar'


//style
const useStyles =
    makeStyles((theme) => ({
        root: {
            width: '100%',
            paddingLeft: '30px',
            paddingRight: '30px',
        },
        paper: {
            marginTop: '23px',
            width: '100%',
            marginBottom: theme.spacing(2),
        },
        table: {
            minWidth: 750,
        },
        floatMobile: {
            [`@media (min-width:${theme.breakpoints.values['ss'] + 1}px)`]: {
                display: 'none'
            }
        },
        tableContainer: {
            paddingLeft: '30px',
            paddingRight: '30px'
        },
        paragraphNoResults: {
            marginTop: '16px'
        },
        grayTone: {
            color: '#0000008a'
        },
        leftFitted: {
            [`@media (max-width:${theme.breakpoints.values['ss']}px)`]: {
                display: 'inline-flex',
                marginLeft: '2rem'
            }
        },
        marginToZero: {
            [`@media (max-width:${theme.breakpoints.values['ss']}px)`]: {
                marginRight: '0px'
            }
        }
    }))


//custom component function
const CustomTable = () => {

    //hooks
    const classes = useStyles()
    const dispatch = useDispatch()
    const theme = useTheme()

    //redux states
    const allRows =
        useSelector(
            state =>
                state
                    .blueprint
                    .table
                    .row)

    const isSearched =
        useSelector(
            state =>
                state
                    .blueprint
                    .table
                    .search
                    .isSearched)

    const allSearchedRows =
        useSelector(
            state =>
                state
                    .blueprint
                    .table
                    .search
                    .row)

    const currentPage =
        useSelector(
            state =>
                state
                    .blueprint
                    .table
                    .pagination
                    .currentPage)

    const rowsPerPage =
        useSelector(
            state =>
                state
                    .blueprint
                    .table
                    .pagination
                    .rows)

    const rowsPerPageOptions =
        useSelector(
            state =>
                state
                    .blueprint
                    .table
                    .pagination
                    .rowOptions)

    const visibleRowsPerPageOptions =
        [rowsPerPageOptions
            .filter(rowNumber =>
                rowNumber > allRows.length)[0]]
            .concat(rowsPerPageOptions
                .filter(rowNumber =>
                    rowNumber <= allRows.length))
            .sort((a, b) => a - b)


    //additional values
    const shouldNextPageButtonBeDisabled = () => {
        if (isSearched) {
            return rowsPerPage *
                (currentPage + 1) >=
                allSearchedRows
                    .length

        } else {
            return rowsPerPage *
                (currentPage + 1) >=
                allRows
                    .length
        }
    }

    const rows =
        isSearched ?
            allSearchedRows :
            allRows

    const widthIsLessThan420px =
        useMediaQuery(`@media(max-width:${theme.breakpoints.values['ss']}px)`)

    const labelDisplayedRows = () => {
        if (!widthIsLessThan420px) {
            if (isSearched) {
                return ({ from, to, count }) => `Showing ${from}-${allSearchedRows.length < to ? allSearchedRows.length : to} of ${allSearchedRows.length} (filtered from ${count} total blueprints)`
            } else {
                return ({ from, to, count }) => `Showing ${from}-${to} of ${count}`
            }

        } else {
            return ({ from, to, count }) => null
        }
    }

    const labelRowsPerPage = () => {
        if (!widthIsLessThan420px) {
            return 'Rows per page:'

        } else {
            return 'Rows:'
        }
    }

    //event handlers
    const onChangePage = (event, page) =>
        dispatch(
            {
                type: 'CHANGE_PAGE_BLUEPRINT_TABLE',
                event: event,
                page: page
            })

    const onChangeRowsPerPage = (event) =>
        dispatch(
            {
                type: 'CHANGE_ROWS_PER_PAGE_BLUEPRINT_TABLE',
                event: event
            })

    //custom component
    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <TableToolbar />
                <TableContainer
                    className={classes.tableContainer}>
                    <Grid item xs={12}>
                        <Table
                            className={classes.table}
                            aria-labelledby="tableTitle"
                            size='medium'
                            aria-label="blueprint table">
                            <TableHead />
                            <TableRows />
                        </Table>
                    </Grid>
                </TableContainer>
                {!rows.length ?
                    <Typography
                        classes={{ paragraph: classes.paragraphNoResults }}
                        paragraph={true}
                        align="center"
                        variant="subtitle1">
                        No results
                    </Typography>
                    : null}
                <TablePagination
                    classes={{
                        caption: classes.grayTone,
                        selectRoot: classes.marginToZero,
                        toolbar: classes.leftFitted
                    }}
                    rowsPerPageOptions={visibleRowsPerPageOptions}
                    labelDisplayedRows={labelDisplayedRows()}
                    labelRowsPerPage={labelRowsPerPage()}
                    component="div"
                    variant="outlined"
                    count={allRows.length}
                    rowsPerPage={rowsPerPage}
                    page={currentPage}
                    onChangePage={onChangePage}
                    nextIconButtonProps={{ disabled: shouldNextPageButtonBeDisabled() }}
                    onChangeRowsPerPage={onChangeRowsPerPage} />
            </Paper>
            <Fab
                color="primary"
                aria-label="add"
                className={classes.floatMobile}>
                <AddIcon />
            </Fab>
        </div>
    )
}

export default CustomTable