//react
import React from 'react'

//material ui hooks/components
import { makeStyles } from '@material-ui/core/styles'

//redux
import { useDispatch, useSelector } from "react-redux"

import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import FormatListBulletedTwoToneIcon from '@material-ui/icons/FormatListBulletedTwoTone'
import TextFieldsIcon from '@material-ui/icons/TextFields'
import DateRangeIcon from '@material-ui/icons/DateRange'


const useStyles =
    makeStyles(theme => ({
        root: {
            width: '1rem',
            height: '5.5rem',
            margin: '1rem',
            color: 'grey',
            background: '#f1f3f4',
            minWidth: '7rem',
            textAlign: 'center',
            "&:hover": {
                boxShadow: '0 5px 5px -3px rgba(0,0,0,.2), 0 8px 10px 1px rgba(0,0,0,.14), 0 3px 14px 2px rgba(0,0,0,.12)',

            }
        },
        rootSelected: {
            width: '1rem',
            height: '5.5rem',
            margin: '1rem',
            color: 'white',
            background: '#1a73e85e',
            minWidth: '7rem',
            textAlign: 'center',
            "&:hover": {
                boxShadow: '0 5px 5px -3px rgba(0,0,0,.2), 0 8px 10px 1px rgba(0,0,0,.14), 0 3px 14px 2px rgba(0,0,0,.12)',

            }
        },
        bullet: {
            display: 'inline-block',
            margin: '0 2px',
            transform: 'scale(0.8)',
        },
        title: {
            fontSize: 13,
            color: 'grey'
        },
        titleSelected: {
            fontSize: 13,
            color: 'white'
        },
        pos: {
            marginBottom: 12
        },
        cardContentRoot: {
            padding: '14px',
            cursor: 'pointer'
        },
        rootSvgIcon: {
            fontSize: '2.5rem',
            backgroundColor: '#8080804d',
            borderRadius: '1.5rem',
            padding: '0.5rem',
            color: 'white'
        },
        rootSvgIconSelected: {
            fontSize: '2.5rem',
            backgroundColor: '#1a73e8',
            borderRadius: '1.5rem',
            padding: '0.5rem',
            color: 'white'
        }
    }))


//custom component function
const Type = () => {

    const classes = useStyles()
    const dispatch = useDispatch()

    //redux states
    const valueMenuCreateStepType =
        useSelector(
            state =>
                state
                    .value
                    .menu
                    .create
                    .stepType)

    //event handlers
    const handleStepTypeClick =
        React.useCallback(
            (event) => {
                console.log('next')
                dispatch(
                    {
                        type: 'TYPE_SELECTED_VALUE_MENU_CREATE',
                        stepType: event.currentTarget.id
                    })
            }, [dispatch])


    //additional data
    const types = [
        {
            key: "list",
            icon: function () { return <FormatListBulletedTwoToneIcon className={valueMenuCreateStepType === this.key ? classes.rootSvgIconSelected : classes.rootSvgIcon} /> },
            title: "Value List"
        },
        {
            key: "freeText",
            icon: function () { return <TextFieldsIcon className={valueMenuCreateStepType === 'freeText' ? classes.rootSvgIconSelected : classes.rootSvgIcon} /> },
            title: "Free Text"
        },
        {
            key: "date",
            icon: function () { return <DateRangeIcon className={valueMenuCreateStepType === 'date' ? classes.rootSvgIconSelected : classes.rootSvgIcon} /> },
            title: "Date Picker"
        }
    ]


    //custom component
    return (
        <React.Fragment>
            {types.map((type, index) => {
                const TypeIcon = type.icon
                return (
                    <Card
                        key={type.key}
                        id={type.key}
                        onClick={(event) => handleStepTypeClick(event)}
                        className={valueMenuCreateStepType === type.key ? classes.rootSelected : classes.root}
                        variant="outlined">
                        <CardContent
                            classes={{ root: classes.cardContentRoot }}>
                            {type.icon()}
                            <Typography
                                component={'div'}
                                className={valueMenuCreateStepType === type.key ? classes.titleSelected : classes.title}
                                gutterBottom>
                                {type.title}
                            </Typography>
                        </CardContent>
                    </Card>
                )
            })
            }
        </React.Fragment >
    )
}

export default Type