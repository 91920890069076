//react
import React from 'react'

//redux
import { useDispatch, useSelector } from "react-redux"

//material ui hooks/components
import { makeStyles } from '@material-ui/core/styles'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Checkbox from '@material-ui/core/Checkbox'


//style
const useStyles =
    makeStyles((theme) => ({
        cellPaddingPointer: {
            padding: '14px'
        },
        cellRoot: {
            cursor: 'pointer'
        }
    }))


//helper functions
const getComparator = (order, orderBy) => {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy)
}

const sortTable = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0])
        if (order !== 0) return order
        return a[1] - b[1]
    });
    return stabilizedThis.map((el) => el[0])
}

const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1
    }
    return 0
}


//custom component function
const Rows = () => {

    //hooks
    const classes = useStyles()
    const dispatch = useDispatch()

    //redux states
    const isBlueprintMenuOpened =
        useSelector(
            state =>
                state
                    .blueprint
                    .menu
                    .choose
                    .isOpened)

    const tableSortColumnOrder =
        useSelector(
            state =>
                state
                    .blueprint
                    .table
                    .sortColumnOrder)

    const tableSortColumnName =
        useSelector(
            state =>
                state
                    .blueprint
                    .table
                    .sortColumnName)

    const selectedRows =
        useSelector(
            state =>
                state
                    .blueprint
                    .table
                    .checkbox
                    .body
                    .selected)

    const rowsPerPage =
        useSelector(
            state =>
                state
                    .blueprint
                    .table
                    .pagination
                    .rows)

    const allRows =
        useSelector(
            state =>
                state
                    .blueprint
                    .table
                    .row)

    const allSearchedRows =
        useSelector(
            state =>
                state
                    .blueprint
                    .table
                    .search
                    .row)

    const currentPage =
        useSelector(
            state =>
                state
                    .blueprint
                    .table
                    .pagination
                    .currentPage)

    const isSearched =
        useSelector(
            state =>
                state
                    .blueprint
                    .table
                    .search
                    .isSearched)

    //additional values
    const isRowSelected =
        name =>
            selectedRows.indexOf(name) !== -1

    const rows =
        isSearched ?
            allSearchedRows :
            allRows

    //event handlers
    const onClickTableRow = (event, row) => {
        if (!isBlueprintMenuOpened) {
            dispatch({
                type: 'CLICK_BODY_CHECKBOX_BLUEPRINT_TABLE',
                event: event,
                rowName: row.name
            })

        } else {
            dispatch({
                type: 'TOGGLE_BLUEPRINT_MENU_CHOOSE'
            })
        }
    }

    //custom component
    return (
        <TableBody>
            {sortTable(
                rows,
                getComparator(
                    tableSortColumnOrder,
                    tableSortColumnName))
                .slice(
                    currentPage * rowsPerPage,
                    currentPage * rowsPerPage + rowsPerPage)

                .map((row, index) => {
                    const isItemSelected =
                        isRowSelected(row.name)

                    return (
                        <TableRow
                            hover
                            onClick={(event) => onClickTableRow(event, row)}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.name}
                            selected={isItemSelected}>
                            {!isBlueprintMenuOpened ?
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        checked={isItemSelected}
                                        inputProps={{ 'aria-labelledby': `custom-table-checkbox-${index}` }}
                                    />
                                </TableCell>
                                : null}
                            <TableCell
                                classes={{ paddingNone: classes.cellPaddingPointer, root: classes.cellRoot }}
                                component="th"
                                id={`custom-table-checkbox-${index}`}
                                scope="row"
                                padding="none">
                                {row.name}
                            </TableCell>
                            <TableCell align="right" classes={{ root: classes.cellRoot }}>{row.calories}</TableCell>
                            <TableCell align="right" classes={{ root: classes.cellRoot }}>{row.fat}</TableCell>
                            <TableCell align="right" classes={{ root: classes.cellRoot }}>{row.carbs}</TableCell>
                            <TableCell align="right" classes={{ root: classes.cellRoot }}>{row.protein}</TableCell>
                        </TableRow>
                    )
                })}
        </TableBody>
    )
}

export default Rows