//react
import React from 'react'

//router
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'

//material ui hooks/components
import { ThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'

//customized own components
import theme from './ui/Theme/Theme'
import Campaign from './ui/Campaign/Campaign'
import Blueprint from './ui/Blueprint/Blueprint'
import PrivateRoute from './auth/PrivateRoute'
import Login from './auth/Login/Login'

//application function
const App = () => {

  //application
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <Switch>
          <Route exact path="/login"><Login /></Route>
          <PrivateRoute exact path="/campaigns" component={Campaign} />
          <PrivateRoute exact path="/blueprints" component={Blueprint} />
          <PrivateRoute exact path="/"><Redirect from="/" to="/campaigns" /></PrivateRoute>
        </Switch>
      </BrowserRouter>
    </ThemeProvider>
  )
}

export default App
