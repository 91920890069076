//react
import React from 'react'

//redux
import { useDispatch, useSelector } from "react-redux"

//material ui hooks/components
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Input from '@material-ui/core/Input';


//style
const useStyles =
    makeStyles(theme => ({
        toolbarMargin: {
            ...theme.mixins.toolbar,
            '@media (min-width:600px)': {
                minHeight: '48px'
            },
            minHeight: '48px'
        },
        toolbar: {
            minHeight: '48px'
        },
        logoContainer: {
            padding: 0,
            "&:hover": {
                backgroundColor: 'transparent'
            }
        },
        drawerIconContainer: {
            marginRigt: 'auto',
            "&:hover": {
                backgroundColor: 'transparent'
            }
        },
        drawerIcon: {
            height: '24px',
            width: '30px',
            color: '#00000080'

        },
        appBar: {
            zIndex: theme.zIndex.modal + 1,
            minHeight: '54px',
            backgroundColor: 'white',
            color: '#000000de'
        },
        titleFirst: {
            ...theme.typography.title,
            flexGrow: 1,
            color: '#000000de',
            marginLeft: '9px',
            textTransform: 'none',
            fontWeight: '450'

        },
        titleSecond: {
            ...theme.typography.title,
            flexGrow: 1,
            color: '#000000de',
            textTransform: 'none'

        },
        avatar: {
            width: '30px',
            height: '30px',
            fontSize: '1rem'
        },
        sectionDesktop: {
            marginLeft: 'auto',
            display: 'flex',
            marginRight: '16px'
        },
        badge: {
            height: '20px',
            minWidth: '20px',
            fontSize: '0.70rem',
            border: `2px solid ${theme.palette.common.blue}`
        },
        headerRoot: {
            width: '75vw',
            borderBottom: '1px solid transparent',
            borderBottomWidth: '1px',
            borderBottomStyle: 'solid',
            borderBottomColor: '#dadce0',
            boxShadow: '0 0 0 0',
            transition: 'width 0.5s ease!important'
        },
        headerRootWhenOtherMenuOpened: {
            width: '100vw',
            borderBottom: '1px solid transparent',
            borderBottomWidth: '1px',
            borderBottomStyle: 'solid',
            borderBottomColor: '#dadce0',
            boxShadow: '0 0 0 0',
            transition: 'width 0.5s ease'
        },
        campaignNameInput: {
            fontSize: '1.2rem'
        }
    }))


//custom component function
const Header = () => {

    //hooks
    const classes = useStyles()
    const dispatch = useDispatch()

    const isVariableMenuChooseOpened =
        useSelector(
            state =>
                state
                    .value
                    .menu
                    .choose
                    .isOpened)


    //event handlers
    const onClickCloseIcon =
        () =>
            dispatch({ type: 'TOGGLE_NAMING_MENU_CREATE' })


    //custom component
    return (
        <React.Fragment>
            <AppBar
                position="fixed"
                className={classes.appBar}
                classes={{
                    root: isVariableMenuChooseOpened ?
                        classes.headerRootWhenOtherMenuOpened :
                        classes.headerRoot
                }}
                color="primary">
                <Toolbar
                    className={classes.toolbar}
                    disableGutters>
                    <IconButton
                        className={classes.drawerIconContainer}
                        onClick={onClickCloseIcon}
                        disableRipple>
                        <CloseIcon
                            className={classes.drawerIcon}>
                        </CloseIcon>
                    </IconButton>
                    <Input
                        classes={{ root: classes.campaignNameInput }}
                        defaultValue="Untitled Naming"
                        inputProps={{ 'aria-label': 'naming' }} />
                    <div className={classes.sectionDesktop}>
                        <Button
                            variant="contained"
                            size="small"
                            color="primary">
                            Save
                </Button>
                    </div>
                </Toolbar>
            </AppBar>
            <div className={classes.toolbarMargin} />
        </React.Fragment >
    )
}

export default Header