const initialState = {
    menu: {
        choose: {
            isOpened: false
        },
        create: {
            isOpened: false
        }
    },
    table: {
        sortColumnName: 'calories',
        sortColumnOrder: 'asc',
        checkbox: {
            header: {
                selected: false
            },
            body: {
                selected: []
            }
        },
        column: [
            { name: 'name', numeric: false, disablePadding: true, label: 'Dessert (100g serving)' },
            { name: 'calories', numeric: true, disablePadding: false, label: 'Calories' },
            { name: 'fat', numeric: true, disablePadding: false, label: 'Fat (g)' },
            { name: 'carbs', numeric: true, disablePadding: false, label: 'Carbs (g)' },
            { name: 'protein', numeric: true, disablePadding: false, label: 'Protein (g)' },
        ],
        row: [
            { name: 'Cupcake', calories: 305, fat: 3.7, carbs: 67, protein: 4.3 },
            { name: 'Donut', calories: 452, fat: 25.0, carbs: 51, protein: 4.9 },
            { name: 'Eclair', calories: 262, fat: 16.0, carbs: 24, protein: 6.0 },
            { name: 'Frozen yoghurt', calories: 159, fat: 6.0, carbs: 24, protein: 4.0 },
            { name: 'Gingerbread', calories: 356, fat: 16.0, carbs: 49, protein: 3.9 },
            { name: 'Honeycomb', calories: 408, fat: 3.2, carbs: 87, protein: 6.5 },
            { name: 'Ice cream sandwich', calories: 237, fat: 9.0, carbs: 37, protein: 4.3 },
            { name: 'Jelly Bean', calories: 375, fat: 0.0, carbs: 94, protein: 0.0 },
            { name: 'KitKat', calories: 518, fat: 26.0, carbs: 65, protein: 7.0 },
            { name: 'Lollipop', calories: 392, fat: 0.2, carbs: 98, protein: 0.0 },
            { name: 'Marshmallow', calories: 318, fat: 0, carbs: 81, protein: 2.0 },
            { name: 'Nougat', calories: 360, fat: 19.0, carbs: 9, protein: 37.0 },
            { name: 'Oreo', calories: 437, fat: 18.0, carbs: 63, protein: 4.0 }
        ],
        pagination: {
            currentPage: 0,
            rows: 5,
            rowOptions: [5, 10, 25, 50, 100, 200]
        },
        search: {
            isSearched: false,
            row: []
        }
    }
}

const naming = (state = initialState, action) => {

    let updatedState = { ...state }

    switch (action.type) {

        case 'TOGGLE_NAMING_MENU_CHOOSE':
            if (state.menu.choose.isOpened) {
                updatedState.menu.choose.isOpened = false

            } else {
                updatedState.menu.choose.isOpened = true
            }

            return updatedState

        case 'TOGGLE_NAMING_MENU_CREATE':
            if (state.menu.create.isOpened) {
                updatedState.menu.create.isOpened = false

            } else {
                updatedState.menu.create.isOpened = true
            }

            return updatedState

        case 'SEARCH_NAMING_TABLE':
            if (action.event.target.value !== "") {

                let columnNames

                let search = state.table.row.filter(row => {
                    columnNames = Object.keys(row)
                    return columnNames.some(columnName => {
                        return row[columnName]
                            .toString()
                            .toLowerCase()
                            .includes(
                                action
                                    .event
                                    .target
                                    .value
                                    .toLowerCase())
                    })
                })

                updatedState.table.pagination.currentPage = 0
                updatedState.table.search.isSearched = true
                updatedState.table.search.row = [...search]
                return updatedState

            } else {
                updatedState.table.pagination.currentPage = 0
                updatedState.table.search.isSearched = false
                return updatedState
            }


        case 'CLICK_BODY_CHECKBOX_NAMING_TABLE':
            const selectedCheckboxes = state.table.checkbox.body.selected
            const selectedIndex = selectedCheckboxes.indexOf(
                action.rowName)

            let newSelected = []

            if (selectedIndex === -1) {
                newSelected = newSelected.concat(
                    selectedCheckboxes, action.rowName)

            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(
                    selectedCheckboxes.slice(1))

            } else if (selectedIndex === selectedCheckboxes.length - 1) {
                newSelected = newSelected.concat(
                    selectedCheckboxes.slice(0, -1))

            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(
                    selectedCheckboxes.slice(
                        0, selectedIndex),
                    selectedCheckboxes.slice(
                        selectedIndex + 1),
                )
            }

            updatedState.checkbox.body.selected = newSelected
            return updatedState


        case 'CLICK_HEADER_CHECKBOX_NAMING_TABLE':
            if (action.event.target.checked) {
                const allRowsSelected =
                    state.table.row.map((n) => n.name);
                updatedState.checkbox.body.selected = allRowsSelected

            } else {
                updatedState.checkbox.body.selected = []
            }

            return updatedState


        case 'SORT_NAMING_TABLE':
            const newOrderType =
                state.table.sortColumnName === action.columnName &&
                    state.table.sortColumnOrder === 'asc' ? 'desc' : 'asc'
            updatedState.table.sortColumnName = action.columnName
            updatedState.table.sortColumnOrder = newOrderType
            return updatedState


        case 'CHANGE_PAGE_NAMING_TABLE':
            updatedState.table.pagination.currentPage = action.page
            return updatedState


        case 'CHANGE_ROWS_PER_PAGE_NAMING_TABLE':
            updatedState.table.pagination.currentPage = 0
            updatedState.table.pagination.rows =
                parseInt(action.event.target.value, 10)
            return updatedState

        default:
            return state
    }
}

export default naming