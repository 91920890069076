//react
import React from 'react'

//redux
import { useDispatch, useSelector } from "react-redux"

//material ui hooks/components
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import Tooltip from '@material-ui/core/Tooltip'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'

//allow multiples classes to be applied
import classNames from 'classnames';


//style
const useStyles =
    makeStyles(
        (theme) => ({
            root: {
                '& > *': {
                    width: '7.5rem',
                    marginBottom: '2rem',
                    display: 'table-row-group',
                }
            },
            settingsBox: {
                display: 'inline-grid!important',
                width: '22rem'
            },
            title: {
                fontSize: 13,
            },
            checkBoxLabel: {
                fontSize: 13,
            },
            checkBoxRoot: {
                marginRight: '0.3rem!important',
                fontSize: 13,
            },
            helperDiv: {
                marginTop: '0.9rem',
                marginLeft: '0.2rem',
                display: 'flex'
            },
            dateSeparator: {
                marginTop: '0.6rem',
                fontSize: 13,
                marginLeft: '2rem'
            },
            formControl: {
                margin: theme.spacing(1),
                minWidth: 90,
                fontSize: '13px!important'
            },
            separatorDivNotVisble: {
                marginTop: '0.75rem',
                display: 'none'
            },
            customDateSeparatorDiv: {
                display: 'inline-flex'
            },
            helpIcon: {
                fontSize: '0.9rem'
            },
            dateFormatPlaceholderFormat: {
                transform: 'translate(21px, 16px) scale(1)',
                fontSize: '13px'
            },
            inputRoot: {
                width: '2.6rem!important',
                marginLeft: '1rem',
                fontSize: '13px',
                fontWeight: '500'
            },
            inputLabel: {
                textAlign: 'center!important'
            },
            container: {
                display: 'inline-flex!important',
                flexWrap: 'wrap',
                marginTop: '0.7rem'
            },
            noMarginLeft: {
                marginLeft: '0px'
            }
        }))


//custom component function
const ConfigurationDatePicker = () => {

    const classes = useStyles()
    const dispatch = useDispatch()

    const elem1 =
        useSelector(
            state =>
                state
                    .value
                    .menu
                    .create
                    .valueDatePicker
                    .stepFormatOptions
                    .elem1)

    const elem2 =
        useSelector(
            state =>
                state
                    .value
                    .menu
                    .create
                    .valueDatePicker
                    .stepFormatOptions
                    .elem2)

    const elem3 =
        useSelector(
            state =>
                state
                    .value
                    .menu
                    .create
                    .valueDatePicker
                    .stepFormatOptions
                    .elem3)

    const allowPastDates =
        useSelector(
            state =>
                state
                    .value
                    .menu
                    .create
                    .valueDatePicker
                    .stepFormatOptions
                    .allowPastDates)

    const removeLeadingZeros =
        useSelector(
            state =>
                state
                    .value
                    .menu
                    .create
                    .valueDatePicker
                    .stepFormatOptions
                    .removeLeadingZeros)

    const customDateSeparator =
        useSelector(
            state =>
                state
                    .value
                    .menu
                    .create
                    .valueDatePicker
                    .stepFormatOptions
                    .customDateSeparator)

    const separatorCharacter =
        useSelector(
            state =>
                state
                    .value
                    .menu
                    .create
                    .valueDatePicker
                    .stepFormatOptions
                    .separatorCharacter)

    const optional =
        useSelector(
            state =>
                state
                    .value
                    .menu
                    .create
                    .valueDatePicker
                    .stepConfigurationSettings
                    .optional)


    //event handlers
    const handleDateElementChange =
        React.useCallback(
            (event, element) => {
                dispatch(
                    {
                        type: 'SELECT_ELEMENT_DATE_CONFIGURATION_STEP_VALUE_MENU_CREATE',
                        element: element,
                        value: event.target.value
                    })
            }, [dispatch])


    const handleCheckboxChange =
        React.useCallback(
            (setting, valueType, block) => {
                dispatch(
                    {
                        type: 'TOGGLE_CHECKBOX_CONFIGURATION_STEP_VALUE_MENU_CREATE',
                        setting: setting,
                        valueType: valueType,
                        block: block
                    })
            }, [dispatch])


    const handleChangeCustomDateSeparator =
        React.useCallback(
            (event) => {
                dispatch(
                    {
                        type: 'CHANGE_DATE_SEPARATOR_CHARACTER_CONFIGURATION_STEP_VALUE_MENU_CREATE',
                        character: event.target.value
                    })
            }, [dispatch])

    //additional data
    const definitions = {
        formatTitle: 'Value Format',
        formatLeadingZero: 'Remove leading zeros',
        formatAllowPastDates: 'Allow past dates',
        formatDateSplitCharacter: 'Custom date separator',
        formatDateSplitCharacterTooltip: `Elements within a date, by default, 
        are separated with a dash "-". Choose this option if you would like to use another 
        character to separate them.`,
        settingsTitle: 'Value Settings',
        settingsOptional: 'Make this value optional',
    }

    //custom component
    return (
        <form
            key="datePicker-configuration-step-form"
            className={classes.root}
            noValidate
            autoComplete="off">
            <Typography
                component={'div'}
                variant='subtitle1'
                className={classes.title}
                color="textSecondary"
                gutterBottom>
                {definitions.formatTitle}
            </Typography>
            <div
                className={classes.settingsBox}>
                <div
                    className={classes.container}>

                    <FormControl
                        variant="outlined"
                        className={classNames(classes.formControl, classes.noMarginLeft)}>
                        <InputLabel
                            id="demo-simple-select-outlined-label"
                            classes={{ outlined: classes.dateFormatPlaceholderFormat }}>
                            Elem.
                        </InputLabel>
                        <Select
                            SelectDisplayProps={{ style: { paddingTop: 13, paddingBottom: 13, fontSize: '13px' } }}
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={elem1}
                            onChange={event => handleDateElementChange(event, 'elem1')}
                            label="Elem">
                            <MenuItem value={'DD'}>DD</MenuItem>
                            <MenuItem value={'MM'}>MM</MenuItem>
                            <MenuItem value={'YY'}>YY</MenuItem>
                            <MenuItem value={'YYYY'}>YYYY</MenuItem>
                        </Select>
                    </FormControl>

                    <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel id="demo-simple-select-outlined-label"
                            classes={{ outlined: classes.dateFormatPlaceholderFormat }}>
                            Elem.
                        </InputLabel>
                        <Select
                            SelectDisplayProps={{ style: { paddingTop: 13, paddingBottom: 13, fontSize: '13px' } }}
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={elem2}
                            onChange={event => handleDateElementChange(event, 'elem2')}
                            label="Elem">
                            <MenuItem value={'DD'}>DD</MenuItem>
                            <MenuItem value={'MM'}>MM</MenuItem>
                            <MenuItem value={'YY'}>YY</MenuItem>
                            <MenuItem value={'YYYY'}>YYYY</MenuItem>
                        </Select>
                    </FormControl>

                    <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel id="demo-simple-select-outlined-label"
                            classes={{ outlined: classes.dateFormatPlaceholderFormat }}>
                            Elem.
                        </InputLabel>
                        <Select
                            SelectDisplayProps={{ style: { paddingTop: 13, paddingBottom: 13, fontSize: '13px' } }}
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={elem3}
                            onChange={event => handleDateElementChange(event, 'elem3')}
                            label="Elem">
                            <MenuItem value={'DD'}>DD</MenuItem>
                            <MenuItem value={'MM'}>MM</MenuItem>
                            <MenuItem value={'YY'}>YY</MenuItem>
                            <MenuItem value={'YYYY'}>YYYY</MenuItem>
                        </Select>
                    </FormControl>
                </div>

                <FormControlLabel
                    value=""
                    control={
                        <Checkbox
                            checked={allowPastDates}
                            onChange={() => handleCheckboxChange('allowPastDates', 'valueDatePicker', 'stepFormatOptions')}
                            inputProps={{ 'aria-label': 'primary checkbox' }} />
                    }
                    label={definitions.formatAllowPastDates}
                    size="small"
                    classes={{ label: classes.checkBoxLabel }}
                    labelPlacement="end" />

                <FormControlLabel
                    value=""
                    control={
                        <Checkbox
                            checked={removeLeadingZeros}
                            onChange={() => handleCheckboxChange('removeLeadingZeros', 'valueDatePicker', 'stepFormatOptions')}
                            inputProps={{ 'aria-label': 'primary checkbox' }} />
                    }
                    label={definitions.formatLeadingZero}
                    size="small"
                    classes={{ label: classes.checkBoxLabel }}
                    labelPlacement="end" />

                <div
                    className={classes.customDateSeparatorDiv}>
                    <FormControlLabel
                        value=""
                        control={
                            <Checkbox
                                checked={customDateSeparator}
                                onChange={() => handleCheckboxChange('customDateSeparator', 'valueDatePicker', 'stepFormatOptions')}
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                        }
                        label={definitions.formatDateSplitCharacter}
                        classes={{ root: classes.checkBoxRoot, label: classes.checkBoxLabel }}
                        labelPlacement="end">

                    </FormControlLabel>
                    <div className={classes.helperDiv}>
                        <Tooltip
                            title={
                                <React.Fragment>
                                    {definitions.formatDateSplitCharacterTooltip}
                                </React.Fragment>
                            }>
                            <HelpOutlineIcon
                                className={classes.helpIcon} />
                        </Tooltip>
                    </div>
                </div>

                <div
                    className={customDateSeparator ? classes.helperDiv : classes.separatorDivNotVisble}>
                    <div>
                        <Typography
                            component={'div'}
                            className={classes.dateSeparator}
                            gutterBottom>
                            Date separator:
                        </Typography>
                    </div>
                    <TextField
                        InputProps={{
                            classes: {
                                root: classes.inputRoot,
                                input: classes.inputLabel
                            }
                        }}
                        inputProps={{
                            maxLength: "1"
                        }}
                        label=""
                        variant="outlined"
                        size="small"
                        value={separatorCharacter}
                        onChange={event => handleChangeCustomDateSeparator(event)}
                    />
                </div>
            </div>

            <Typography
                component={'div'}
                variant='subtitle1'
                className={classes.title}
                color="textSecondary"
                gutterBottom>
                {definitions.settingsTitle}
            </Typography>
            <div
                className={classes.settingsBox}>
                <FormControlLabel
                    value=""
                    control={
                        <Checkbox
                            checked={optional}
                            onChange={() => handleCheckboxChange('optional', 'valueDatePicker', 'stepConfigurationSettings')}
                            inputProps={{ 'aria-label': 'primary checkbox' }} />
                    }
                    label={definitions.settingsOptional}
                    size="small"
                    classes={{ label: classes.checkBoxLabel }}
                    labelPlacement="end" />
            </div>
        </form>
    )
}

export default React.memo(ConfigurationDatePicker)