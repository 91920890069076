//react
import React from 'react'

//redux
import { useDispatch, useSelector } from "react-redux"

//material ui hooks/components
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'


//style
const useStyles =
    makeStyles(theme => ({
        toolbarMargin: {
            ...theme.mixins.toolbar,
            '@media (min-width:600px)': {
                minHeight: '48px'
            },
            minHeight: '48px'
        },
        toolbar: {
            minHeight: '48px'
        },
        iconButton: {
            marginRigt: 'auto',
            "&:hover": {
                backgroundColor: 'transparent'
            }
        },
        closeIcon: {
            height: '24px',
            width: '30px',
            color: '#00000080'

        },
        appBar: {
            zIndex: theme.zIndex.modal + 1,
            minHeight: '54px',
            backgroundColor: 'white',
            color: '#000000de'
        },
        iconsAppBarRight: {
            marginLeft: 'auto',
            display: 'flex',
            marginRight: '16px'
        },
        headerRoot: {
            width: '75vw',
            borderBottom: '1px solid transparent',
            borderBottomWidth: '1px',
            borderBottomStyle: 'solid',
            borderBottomColor: '#dadce0',
            boxShadow: '0 0 0 0',
            transition: 'width 0.5s ease!important'
        },
        headerRootWhenOtherMenuOpened: {
            width: '100vw',
            borderBottom: '1px solid transparent',
            borderBottomWidth: '1px',
            borderBottomStyle: 'solid',
            borderBottomColor: '#dadce0',
            boxShadow: '0 0 0 0',
            transition: 'width 0.5s ease!important'
        },
        paragraphTypography: {
            marginBottom: '0px',
            fontSize: '1.3rem'
        }
    }))


//custom component function
const Header = () => {

    //hooks
    const classes = useStyles()
    const dispatch = useDispatch()

    //redux states
    const isBlueprintMenuCreateOpened =
        useSelector(
            state =>
                state
                    .blueprint
                    .menu
                    .create
                    .isOpened)

    //event handlers
    const onClickCloseIcon =
        () =>
            dispatch({ type: 'TOGGLE_BLUEPRINT_MENU_CHOOSE' })

    //custom component
    return (
        <React.Fragment>
            <AppBar
                position="fixed"
                className={classes.appBar}
                classes={{
                    root: isBlueprintMenuCreateOpened ?
                        classes.headerRootWhenOtherMenuOpened :
                        classes.headerRoot
                }}
                color="primary">
                <Toolbar
                    className={classes.toolbar}
                    disableGutters>
                    <IconButton
                        className={classes.iconButton}
                        onClick={onClickCloseIcon}
                        disableRipple>
                        <CloseIcon
                            className={classes.closeIcon}>
                        </CloseIcon>
                    </IconButton>
                    <Typography
                        classes={{ paragraph: classes.paragraphTypography }}
                        paragraph={true}
                        align="center"
                        variant="subtitle1">
                        Choose Blueprint
                    </Typography>
                    <div className={classes.iconsAppBarRight}>
                        <Button
                            classes={{ root: classes.rootButtonAdCampaign }}
                            variant="contained"
                            size="small"
                            color="primary">
                            Save
                        </Button>
                    </div>
                </Toolbar>
            </AppBar>
            <div className={classes.toolbarMargin} />
        </React.Fragment >
    )
}

export default Header