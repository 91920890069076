//react
import React from 'react'

//redux
import { useDispatch, useSelector } from "react-redux"

//material ui hooks/components
import { makeStyles } from '@material-ui/core/styles'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Checkbox from '@material-ui/core/Checkbox'


//style
const useStyles =
    makeStyles((theme) => ({
        hideSortArrow: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1
        },
        tableHeaderRoot: {
            backgroundColor: '#0000000a'
        }
    }))


//custom component function
const Header = () => {

    //hooks
    const classes = useStyles()
    const dispatch = useDispatch()

    //redux states
    const tableSortColumnOrder =
        useSelector(
            state =>
                state
                    .campaign
                    .table
                    .sortColumnOrder)

    const tableSortColumnName =
        useSelector(
            state =>
                state
                    .campaign
                    .table
                    .sortColumnName)

    const selectedRows =
        useSelector(
            state =>
                state
                    .campaign
                    .table
                    .checkbox
                    .body
                    .selected)

    const allRows =
        useSelector(
            state =>
                state
                    .campaign
                    .table
                    .row)

    const allColumns =
        useSelector(
            state =>
                state
                    .campaign
                    .table
                    .column)


    //additional values
    const isCheckAllIndeterminate =
        selectedRows.length > 0 &&
        selectedRows.length <
        allRows.length

    const isCheckAllChecked =
        allRows.length > 0 &&
        selectedRows.length ===
        allRows.length

    const tableSortArrow =
        tableSortColumnOrder === 'desc' ?
            'sorted descending' :
            'sorted ascending'

    const tableCellAlignment =
        (column) =>
            column.numeric ?
                'right' :
                'left'

    const tableCellPadding =
        (column) =>
            column.disablePadding ?
                'none' :
                'default'

    const tableCellSortDirection =
        (column) =>
            tableSortColumnName === column.name ?
                tableSortColumnOrder :
                false

    const tableSortLabelActive =
        (column) =>
            tableSortColumnName === column.name

    const tableSortDirection =
        (column) =>
            tableSortColumnName === column.name ?
                tableSortColumnOrder :
                'asc'

    const isSortedColumn =
        (column) =>
            tableSortColumnName === column.name

    const tableCellInputProps = {
        'aria-label': 'select all campaigns'
    }


    //event handlers
    const onChangeHeaderCheckbox = (event) =>
        dispatch(
            {
                type: 'CLICK_HEADER_CHECKBOX_CAMPAIGN_TABLE',
                event: event
            })

    const onClickTableSort = (event, column) => {
        dispatch(
            {
                type: 'SORT_CAMPAIGN_TABLE',
                columnName: column.name
            })
    }


    //custom component
    return (
        <TableHead
            classes={{ root: classes.tableHeaderRoot }}>
            <TableRow>
                <TableCell
                    padding="checkbox">
                    <Checkbox
                        indeterminate={isCheckAllIndeterminate}
                        checked={isCheckAllChecked}
                        onChange={onChangeHeaderCheckbox}
                        inputProps={tableCellInputProps} />
                </TableCell>

                {allColumns.map((column) => (
                    <TableCell
                        key={column.name}
                        align={tableCellAlignment(column)}
                        padding={tableCellPadding(column)}
                        sortDirection={tableCellSortDirection(column)}>

                        <TableSortLabel
                            active={tableSortLabelActive(column)}
                            direction={tableSortDirection(column)}
                            onClick={(event) => onClickTableSort(event, column)}>
                            {column.label}
                            {
                                isSortedColumn(column) ?
                                    (
                                        <span className={classes.hideSortArrow}>
                                            {tableSortArrow}
                                        </span>
                                    ) :
                                    null
                            }
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    )
}

export default Header