const initialState = {
    menu: {
        navigation: {
            isOpened: false
        }
    },
    header: {
        settingsPopover: {
            isOpened: false,
            anchor: ''
        }
    }
}


const common = (state = initialState, action) => {

    let updatedState = { ...state }

    switch (action.type) {

        case 'TOGGLE_SETTINGS_POPOVER':
            if (action.event &&
                !state.header.settingsPopover.isOpened) {
                updatedState.header.settingsPopover.isOpened = true
                updatedState.header.settingsPopover.elementToAnchorTheMenu = action.event

            } else {
                updatedState.header.settingsPopover.isOpened = false
                updatedState.header.settingsPopover.elementToAnchorTheMenu = null
            }

            return updatedState

        case 'TOGGLE_NAVIGATION_MENU':
            if (state.menu.navigation.isOpened) {
                updatedState.menu.navigation.isOpened = false

            } else {
                updatedState.menu.navigation.isOpened = true
            }

            return updatedState

        default:
            return state
    }
}

export default common