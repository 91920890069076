//react
import React from 'react'

//redux
import { useDispatch, useSelector } from "react-redux"

//material ui hooks/components
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import Tooltip from '@material-ui/core/Tooltip'
import ChipInput from 'material-ui-chip-input'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'


//style
const useStyles =
    makeStyles(
        (theme) => ({
            root: {
                '& > *': {
                    width: '7.5rem',
                    marginBottom: '2rem',
                    display: 'table-row-group',
                }
            },
            valueBox: {
                display: 'initial!important'
            },
            settingsBox: {
                display: 'inline-grid!important',
                width: '22rem'
            },
            removeValueVisible: {
                marginLeft: 'auto',
                display: 'block',
                color: '#dc004e'
            },
            removeValue: {
                display: 'none'
            },
            title: {
                fontSize: 13,
            },
            checkBoxLabel: {
                fontSize: '13px!important'
            },
            checkBoxRoot: {
                marginRight: '0.3rem!important',
                fontSize: '13px!important'
            },
            helperDiv: {
                marginTop: '0.10rem',
                display: 'flex',
                marginLeft: '2rem'
            },
            helperDivHelp: {
                marginTop: '0.9em',
                display: 'flex'
            },
            concatenateDivNotVisble: {
                marginTop: '0.75rem',
                display: 'none'
            },
            multipleOptionsDiv: {
                display: 'inline-flex'
            },
            helpIcon: {
                fontSize: '0.9rem'
            },
            chipBox: {
                marginTop: '0.6rem',
                paddingTop: '0px'
            },
            chipBoxRemoveButtonVisible: {
                marginTop: '0.6rem',
                marginBottom: '0.6rem',
                paddingTop: '0px'
            },
            chipInputRoot: {
                paddingTop: '8px!important',
                fontSize: '13px',
                minWidth: '16rem'
            },
            classRadioLabel: {
                fontSize: '13px'
            }
        }))


//custom component function
const ConfigurationFreeText = () => {

    const classes = useStyles()
    const dispatch = useDispatch()

    //redux states
    const letter =
        useSelector(
            state =>
                state
                    .value
                    .menu
                    .create
                    .valueFreeText
                    .stepConfigurationOptions
                    .letter)

    const number =
        useSelector(
            state =>
                state
                    .value
                    .menu
                    .create
                    .valueFreeText
                    .stepConfigurationOptions
                    .number)

    const character =
        useSelector(
            state =>
                state
                    .value
                    .menu
                    .create
                    .valueFreeText
                    .stepConfigurationOptions
                    .character)

    const characters =
        useSelector(
            state =>
                state
                    .value
                    .menu
                    .create
                    .valueFreeText
                    .stepConfigurationOptions
                    .characters)

    const optional =
        useSelector(
            state =>
                state
                    .value
                    .menu
                    .create
                    .valueFreeText
                    .stepConfigurationSettings
                    .optional)

    const lowerUpperCase =
        useSelector(
            state =>
                state
                    .value
                    .menu
                    .create
                    .valueFreeText
                    .stepConfigurationSettings
                    .lowerUpperCase)

    const lowerUpperCaseOption =
        useSelector(
            state =>
                state
                    .value
                    .menu
                    .create
                    .valueFreeText
                    .stepConfigurationSettings
                    .lowerUpperCaseOption)


    //event handlers
    const handleAddCharacter =
        React.useCallback(
            (character) => {
                dispatch(
                    {
                        type: 'ADD_CHARACTER_CONFIGURATION_STEP_VALUE_MENU_CREATE',
                        character: character
                    })
            }, [dispatch])

    const handleDeleteCharacter =
        React.useCallback(
            (character) => {
                dispatch(
                    {
                        type: 'REMOVE_CHARACTER_CONFIGURATION_STEP_VALUE_MENU_CREATE',
                        character: character
                    })
            }, [dispatch])

    const handleRemoveAllCharacters =
        React.useCallback(
            () => {
                dispatch(
                    {
                        type: 'REMOVE_ALL_CHARACTERS_CONFIGURATION_STEP_VALUE_MENU_CREATE',
                    })
            }, [dispatch])

    const handleCheckboxChange =
        React.useCallback(
            (setting, valueType, block) => {
                dispatch(
                    {
                        type: 'TOGGLE_CHECKBOX_CONFIGURATION_STEP_VALUE_MENU_CREATE',
                        setting: setting,
                        valueType: valueType,
                        block: block
                    })
            }, [dispatch])


    const handleCaseChange =
        React.useCallback(
            (event) => {
                dispatch(
                    {
                        type: 'CHANGE_CASE_CONFIGURATION_STEP_VALUE_MENU_CREATE',
                        case: event.currentTarget.value
                    })
            }, [dispatch])


    //additional data
    const definitions = {
        optionsTitle: 'Value Options',
        optionsLetters: 'Allow letter characters',
        optionsNumbers: 'Allow number characters',
        optionsSpecial: 'Allow special characters',
        optionsPlaceholder: 'Type here the options for the value...',
        settingsTitle: 'Value Settings',
        settingsMultipleTooltip: `If this feature is enabled, it will 
        enforce users to type text on lowercase or upper case. If 
        this feature isn't enabled, by default the user will be allowed 
        to input any case type. `,
        settingsOptional: 'Make this value optional',
        settingsForceCase: 'Force lower/upper case',
        settingsConcatParameter: 'Concatenation parameter:'
    }


    //custom component
    return (
        <form
            key="freeText-configuration-step-form"
            className={classes.root}
            noValidate
            autoComplete="off">
            <Typography
                component={'div'}
                variant='subtitle1'
                className={classes.title}
                color="textSecondary"
                gutterBottom>
                {definitions.optionsTitle}
            </Typography>
            <div
                className={classes.settingsBox}>
                <FormControlLabel
                    value=""
                    control={
                        <Checkbox
                            checked={letter}
                            onChange={() => handleCheckboxChange('letter', 'valueFreeText', 'stepConfigurationOptions')}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                    }
                    label={definitions.optionsLetters}
                    size="small"
                    classes={{ label: classes.checkBoxLabel }}
                    labelPlacement="end"
                />
                <FormControlLabel
                    value=""
                    control={
                        <Checkbox
                            checked={number}
                            onChange={() => handleCheckboxChange('number', 'valueFreeText', 'stepConfigurationOptions')}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                    }
                    label={definitions.optionsNumbers}
                    size="small"
                    classes={{ label: classes.checkBoxLabel }}
                    labelPlacement="end"
                />
                <div
                    className={classes.multipleOptionsDiv}
                    classes={{}}>
                    <FormControlLabel
                        value=""
                        control={
                            <Checkbox
                                checked={character}
                                onChange={() => handleCheckboxChange('character', 'valueFreeText', 'stepConfigurationOptions')}
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                        }
                        label={definitions.optionsSpecial}
                        classes={{ label: classes.checkBoxLabel }}
                        labelPlacement="end">
                    </FormControlLabel>
                </div>
                <div
                    className={character ? classes.helperDiv : classes.concatenateDivNotVisble}>
                    <div
                        className={classes.valueBox}
                        id="chipWrapper">
                        <ChipInput
                            id="chipInput"
                            inputRef={input => input && input.focus()}
                            classes={{
                                inputRoot: classes.chipInputRoot
                            }}
                            className={characters.length > 10 ? classes.chipBoxRemoveButtonVisible : classes.chipBox}
                            placeholder={definitions.optionsPlaceholder}
                            onPaste={(event) => {
                                event.preventDefault()
                                handleAddCharacter(event.clipboardData.getData('Text').split('\n'))
                            }}
                            value={characters}
                            newChipKeys={["Control"]}
                            variant="outlined"
                            onAdd={(chip) => handleAddCharacter([chip])}
                            onDelete={(chip) => handleDeleteCharacter(chip)}
                        />
                        <Button
                            className={characters.length > 10 ? classes.removeValueVisible : classes.removeValue}
                            onClick={handleRemoveAllCharacters}>
                            Remove All
                        </Button>
                    </div>
                </div>
            </div>


            <Typography
                component={'div'}
                variant='subtitle1'
                className={classes.title}
                color="textSecondary"
                gutterBottom>
                {definitions.settingsTitle}
            </Typography>
            <div
                className={classes.settingsBox}>
                <FormControlLabel
                    value=""
                    control={
                        <Checkbox
                            checked={optional}
                            onChange={() => handleCheckboxChange('optional', 'valueFreeText', 'stepConfigurationSettings')}
                            inputProps={{ 'aria-label': 'primary checkbox' }} />
                    }
                    label={definitions.settingsOptional}
                    size="small"
                    classes={{ label: classes.checkBoxLabel }}
                    labelPlacement="end"
                />

                {/*Lower/upper case*/}
                <div
                    className={classes.multipleOptionsDiv}>

                    <FormControlLabel
                        value=""
                        control={
                            <Checkbox
                                checked={lowerUpperCase}
                                onChange={() => handleCheckboxChange('lowerUpperCase', 'valueFreeText', 'stepConfigurationSettings')}
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                        }
                        label={definitions.settingsForceCase}
                        classes={{ root: classes.checkBoxRoot, label: classes.checkBoxLabel }}
                        labelPlacement="end">
                    </FormControlLabel>

                    <div className={classes.helperDivHelp}>
                        <Tooltip
                            title={
                                <React.Fragment>
                                    {definitions.settingsMultipleTooltip}
                                </React.Fragment>
                            }>
                            <HelpOutlineIcon
                                className={classes.helpIcon} />
                        </Tooltip>
                    </div>
                </div>
                <div
                    className={lowerUpperCase ? classes.helperDiv : classes.concatenateDivNotVisble}>
                    <RadioGroup
                        aria-label="quiz"
                        name="quiz"
                        value={lowerUpperCaseOption}
                        onChange={(event) => handleCaseChange(event)}>
                        <FormControlLabel
                            classes={{ label: classes.classRadioLabel }}
                            value="lowercase"
                            control={<Radio size="small" />}
                            label="Force lowercase" />
                        <FormControlLabel
                            classes={{ label: classes.classRadioLabel }}
                            value="uppercase"
                            control={<Radio size="small" />}
                            label="Force uppercase" />
                    </RadioGroup>
                </div>
            </div>
        </form>
    )
}

export default React.memo(ConfigurationFreeText)