//react
import React from 'react'

//redux
import { useDispatch, useSelector } from "react-redux"

//material ui hooks/components
import { makeStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import Grid from '@material-ui/core/Grid'

//customized own components/material
import Header from './Header'
import Table from '../Table/Table'


//style
const useStyles =
    makeStyles(theme => ({
        toolbarMargin: {
            ...theme.mixins.toolbar,
            '@media (min-width:600px)': {
                minHeight: '35px'
            },
            minHeight: '35px'
        },
        paperDrawer: {
            backgroundColor: '#f1f3f4',
            width: '75vw',
            overflow: 'hidden',
        },
        paperDrawerResize: {
            backgroundColor: '#f1f3f4',
            width: '75vw',
            overflow: 'hidden',
            transition: 'width 0.5s ease!important'
        },
        paperDrawerWhenOtherMenuOpened: {
            backgroundColor: '#f1f3f4',
            width: '100vw',
            overflow: 'hidden',
            transition: 'width 0.5s ease!important'
        },
        upperGridroot: {
            flexGrow: 1,
        },
        gridRoot: {
            height: '100%',
            cursor: 'pointer',
            overflow: 'scroll'
        },
        rootSvgIcon: {
            fontSize: '4.3rem'
        }
    }))


//custom component function
const Menu = () => {

    //hooks
    const classes = useStyles()
    const dispatch = useDispatch()

    //redux states
    const isBlueprintMenuChooseOpened =
        useSelector(
            state =>
                state
                    .blueprint
                    .menu
                    .choose
                    .isOpened)

    const isBlueprintMenuCreateOpened =
        useSelector(
            state =>
                state
                    .blueprint
                    .menu
                    .create
                    .isOpened)

    //event handlers
    const onBackdropClick = (event) =>
        dispatch(
            {
                type: 'TOGGLE_BLUEPRINT_MENU_CHOOSE',
                event: event
            })

    //additional data
    const isBlueprintMenuChoose100vw =
        document.querySelectorAll('#blueprintChooseDrawer >div.MuiPaper-root')[0] ?
            (document.querySelectorAll('#blueprintChooseDrawer >div.MuiPaper-root')[0]
                .offsetWidth === window.innerWidth) : false


    //custom component
    return (
        <React.Fragment>
            <Drawer
                id='blueprintChooseDrawer'
                transitionDuration={{ enter: 500, exit: 500 }}
                anchor="right"
                variant="temporary"
                open={isBlueprintMenuChooseOpened}
                onBackdropClick={onBackdropClick}
                PaperProps={{
                    classes: {
                        root:
                            isBlueprintMenuCreateOpened ?
                                classes.paperDrawerWhenOtherMenuOpened :
                                (isBlueprintMenuChoose100vw ? classes.paperDrawerResize : classes.paperDrawer)
                    }
                }}>
                <Header />
                <div className={classes.toolbarMargin} />
                <Grid
                    container
                    classes={{ root: classes.gridRoot }}
                    className={classes.upperGridroot}
                    spacing={2}>
                    <Grid
                        item xs={12}>
                        <Grid
                            container
                            direction="column"
                            alignItems="center"
                            justify="center"
                            spacing={2}>
                            <Table />
                        </Grid>
                    </Grid>
                </Grid>
            </Drawer>
        </React.Fragment >
    )
}

export default Menu