//react
import React from 'react'

//redux
import { useDispatch, useSelector } from "react-redux"

//material ui hooks/components
import { makeStyles } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import DeleteIcon from '@material-ui/icons/Delete'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import SearchIcon from '@material-ui/icons/Search'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import Button from '@material-ui/core/Button'
import AddBoxIcon from '@material-ui/icons/AddBox'


//style
const useStyles =
    makeStyles((theme) => ({
        searchInput: {
            marginTop: '32px',
            marginLeft: '5px',
            marginBottom: '19px',
            width: '50%',
            [theme.breakpoints.down('xs')]: {
                marginLeft: '13px'
            },
            [`@media (max-width:${theme.breakpoints.values['ss']}px)`]: {
                width: '100%'
            }
        },
        resizeSearcher: {
            fontSize: '0.875rem',
            paddingTop: '14px',
            paddingBottom: '14px'
        },
        selectedIcons: {
            marginLeft: 'auto',
            marginRight: '7px',
            marginTop: '5px',
            [theme.breakpoints.down('xs')]: {
                marginRight: '0px'
            }
        },
        rootButtonAddValue: {
            marginLeft: '12px',
            [theme.breakpoints.down('xs')]: {
                marginLeft: '4px'
            },
            [`@media (max-width:${theme.breakpoints.values['ss']}px)`]: {
                display: 'none'
            }
        },
        hideIcons: {
            [`@media (max-width:${theme.breakpoints.values['mm']}px)`]: {
                display: 'none'
            }
        }
    }))


//custom component function
const TableToolbar = () => {

    //hooks
    const classes = useStyles()
    const dispatch = useDispatch()

    //redux states
    const selectedRows =
        useSelector(
            state =>
                state
                    .value
                    .table
                    .checkbox
                    .body
                    .selected)

    //event handlers
    const handleSearch =
        event => {
            dispatch({
                type: 'SEARCH_VALUE_TABLE',
                event: event
            })
        }

    const onClickAddValue =
        () =>
            dispatch({ type: 'TOGGLE_VALUE_MENU_CREATE' })

    //custom component
    return (
        <Toolbar>
            <TextField
                margin="dense"
                variant="outlined"
                label="Search Values"
                onChange={handleSearch}
                className={classes.searchInput}
                InputProps={{
                    classes: {
                        input: classes.resizeSearcher,
                    },
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon color='disabled' />
                        </InputAdornment>)
                }} />
            <div
                className={classes.selectedIcons}>
                {selectedRows.length > 0 ?
                    (
                        <React.Fragment>
                            <Tooltip
                                className={classes.hideIcons}
                                title="Copy">
                                <IconButton
                                    classes={{ label: classes.labelIconButton }}
                                    aria-label="copy">
                                    <FileCopyIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip
                                className={classes.hideIcons}
                                title="Delete">
                                <IconButton
                                    classes={{ label: classes.labelIconButton }}
                                    aria-label="delete">
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>

                        </React.Fragment>
                    )
                    : null}
                <Button
                    onClick={onClickAddValue}
                    classes={{ root: classes.rootButtonAddValue }}
                    variant="contained"
                    size="medium"
                    color="primary"
                    startIcon={<AddBoxIcon />}>
                    Add Value
                </Button>
            </div>
        </Toolbar>
    )
}

export default TableToolbar