//react
import React from 'react'

//material ui hooks/components
import { makeStyles } from '@material-ui/core/styles'

//customized own components
import Header from '../Common/Header/Header'
import SectionTitle from '../Common/Section/Title'
import Table from './Table/Table'
import LeftMenu from '../Common/Menu/Navigation'
import CampaignMenuCreate from './Create/Menu'
import BlueprintMenuChoose from '../../ui/Blueprint/Choose/Menu'
import BlueprintMenuCreate from '../../ui/Blueprint/Create/Menu'
import NamingMenuChoose from '../../ui/Naming/Choose/Menu'
import NamingMenuCreate from '../../ui/Naming/Create/Menu'
import ValueMenuChoose from '../../ui/Value/Choose/Menu'
import ValueMenuCreate from '../../ui/Value/Create/Menu'

//style
const useStyles =
    makeStyles(theme => ({
        toolbarMargin: {
            ...theme.mixins.toolbar,
            '@media (min-width:600px)': {
                minHeight: '48px'
            },
            minHeight: '48px'
        }
    }))


//custom component function
const Campaign = () => {

    //hooks
    const classes = useStyles()

    //custom component
    return (
        <React.Fragment>
            <Header />
            <div className={classes.toolbarMargin} />
            <SectionTitle />
            <Table />
            <LeftMenu />
            <CampaignMenuCreate />
            <BlueprintMenuChoose />
            <BlueprintMenuCreate />
            <NamingMenuChoose />
            <NamingMenuCreate />
            <ValueMenuChoose />
            <ValueMenuCreate />
        </React.Fragment >
    )
}

export default Campaign